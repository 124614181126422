import React, { useState, useEffect } from "react";
import useDidMountEffect from "../elements/useDidMountEffect";
import { Button, Form, Tabs, Tab } from "react-bootstrap";
import InputTheme from "../elements/InputTheme";
import InputBox from "../elements/InputBox";
import InputSelect from "../elements/InputSelect";
import InputButtons from "../elements/InputButtons";
import InputCourse from "../elements/InputCourse";
import calculator from "../elements/calculator";
import handleValidate from "../elements/validator";
import HintDismissable from "../elements/hint";
import ModalLoanInput from "../elements/modalLoanInput";
import inputData from "../data/inputData";
import historicalData from "../data/historicalData";
import { irr } from "node-irr";
import csvExporter from "../elements/csvExport";
import Scroll from "react-scroll";

const NavLink = Scroll.Link;

const handleInput = (e, input) => {
  let output = {};
  Object.assign(output, input.studentData);

  let newInUse = {};
  Object.assign(newInUse, { ...input.inUse });
  newInUse.submit = false;
  input.setInUse({ ...newInUse });

  // If we are dealing with a checkbox, handle things differently:

  console.log(e.target.value);

  console.log(e.target.name);
  if (e.target.value === "checked") {
    output[e.target.name] = "unchecked";
  } else if (e.target.value === "unchecked") {
    output[e.target.name] = "checked";
  } else if (e.target.value === "") {
    output[e.target.name] = "";
  } else if (e.target.type === "select-one") {
    output[e.target.name] = e.target.value;
  } else {
    output[e.target.name] = parseFloat(e.target.value);
  }
  if (e.target.name === "courseStartYear") {
    output = handleStartYear(output);
    output = handlePlan(output);
  }

  if (e.target.name === "loanPlan") {
    output = handlePlan(output);
  }

  input.setStudentData(output);
  input.setAutoData(output);
};

const handleSubmit = (e, props) => {
  if (
    props.studentData.courseLength + props.studentData.courseStartYear >
      props.studentData.currentYear &&
    props.studentData.setLoanSize === "checked"
  ) {
    return null;
  }
  // Auto input always adds an overpayment of £X more than the inputted value
  let preAutoData = {};
  Object.assign(preAutoData, props.autoData);
  preAutoData.overpayment = 100 + props.studentData.overpayment;

  console.log("student data", props.studentData, preAutoData);

  // Get data validated
  let input = handleValidate(e, props.studentData);
  let autoInput = handleValidate(e, preAutoData);

  let calculated = calculator(input, props.manualData);
  let autoCalculated = calculator(autoInput, props.manualData);

  let output = {};
  Object.assign(output, input);
  let autoOutput = {};
  Object.assign(autoOutput, autoInput);

  output.summaryData = calculated.summaryData;
  autoOutput.summaryData = autoCalculated.summaryData;

  output.data = calculated.data;
  autoOutput.data = autoCalculated.data;

  let irrArray = [];
  output.summaryData.repaymentScheduleNominal.forEach((item, index) => {
    // item - current value in the loop
    // index - index for this value in the array
    // arr - reference to analyzed array
    irrArray.push(
      item - autoOutput.summaryData.repaymentScheduleNominal[index]
    );
  });

  let adjustedArray = irrArray;

  if (output.setLoanSize === "unchecked") {
    adjustedArray = irrArray.slice(output.courseLength);
  }

  // console.log(adjustedArray);

  let apr = irr(adjustedArray);
  // console.log(apr);

  output.apr = apr;

  // Export CSV
  let rows = [];
  rows.push(Object.keys(output.data[0]));
  // console.log(output.data[0]);
  output.data.forEach((el) => {
    rows.push(Object.values(el));
  });

  let encodedUri = encodeURI(csvExporter(rows));
  let link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", "Student Loan Data.csv");
  document.body.appendChild(link); // Required for FF
  // console.log("firing submit");

  if (props.inUse.comparing) {
    let forInUse = {};
    Object.assign(forInUse, { ...props.inUse });
    forInUse.inUse = [true, true];
    // console.log("firing submit 1");
    forInUse.recalculate = [true, true];
    forInUse.submit = true;
    // console.log(forInUse);
    props.setComparisonData(output);
    props.setInUse({ ...forInUse });
  } else {
    let forInUse = {};
    Object.assign(forInUse, { ...props.inUse });
    console.log("firing submit 2");
    console.log(forInUse);
    forInUse.recalculate[0] = true;
    forInUse.submit = true;

    props.setInUse({ ...forInUse });
    props.setStudentData(output);
    props.setAutoData(autoOutput);
    props.setCsvData(link);
  }
};

const handleReset = (e, input) => {
  // console.log("firing reset");
  input.setStudentData(inputData.default);
  input.setComparisonData(inputData.default);
  input.setAutoData(inputData.default);
  // console.log("inputData", inputData);
  // console.log("firing reset 1", inputData.inUseDefault);
  input.setInUse({
    inUse: [true, false],
    variants: ["primary", "warning"],
    cache: [],
    comparing: false,
    recalculate: [false, false],
  });
};

const handleKeyUp = (event, props) => {
  if (event.key === "Enter") {
    handleSubmit(event, props);
  }
};

const handleStartYear = (input) => {
  let output = input;
  // If pre-2023 and set to plan5, set to plan2
  if (output.courseStartYear < 2023) {
    if (output.loanPlan !== undefined) {
      if (output.loanPlan === "plan5") {
        output.loanPlan = "plan2";
      }
    }
  }
  // If pre-2012 and set to default or to plan2, set to plan1
  if (output.courseStartYear < 2012) {
    if (output.loanPlan !== undefined) {
      if (output.loanPlan === "plan2") {
        output.loanPlan = "plan1";
      }
    } else {
      output.loanPlan = "plan1";
    }
  }
  // If pre-2016 and set to planPostgrad, set to plan1
  if (output.courseStartYear < 2016) {
    if (output.loanPlan !== undefined) {
      if (output.loanPlan === "planPostgrad") {
        output.loanPlan = "plan1";
      }
    }
  }
  // If post-2011 and set to plan1, set to plan2
  if (output.courseStartYear > 2011) {
    if (output.loanPlan !== undefined) {
      if (output.loanPlan === "plan1") {
        output.loanPlan = "plan2";
      }
    }
  }
  return output;
};

const handlePlan = (input) => {
  let output = input;

  // Set the floor to the default for that loan type:
  output.currentPaymentFloor = historicalData.defaultPlanFloor[output.loanPlan];

  return output;
};

/**
 * InputPanel component renders the input fields and controls for the student loan calculator.
 *
 * @component
 * @param {Object} props - The props object containing the necessary data and functions.
 * @param {boolean} props.manualData - Indicates whether the manual data has been updated.
 * @param {Object} props.studentData - The student data object containing loan and course details.
 * @param {Function} props.handleInput - The function to handle input changes.
 * @param {Function} props.handleKeyUp - The function to handle key up events.
 * @returns {JSX.Element} The JSX element representing the InputPanel component.
 */
const InputPanel = (props) => {
  const [showModal, setShowModal] = useState(false);

  useDidMountEffect(() => {
    handleSubmit(null, props);
  }, [props.manualData]);

  let arr = [];
  for (let i = 0; i < props.studentData.courseLength; i++) {
    arr.push(props.studentData.courseStartYear + i);
  }

  return (
    <div className="inputPanel">
      <ModalLoanInput
        value={{
          dataName: props.studentData.salary,
          step: 50,
          type: "number",
          data: props,
          handleInput: handleInput,
          handleKeyUp: handleKeyUp,
          arr: arr,
        }}
        showModal={showModal}
        setShowModal={setShowModal}
      />
      <Tabs
        defaultActiveKey="info"
        id="uncontrolled-tab-example"
        variant="pills"
        className="inputTabsFlex">
        <Tab
          style={{ margin: "10px 3px 0px 3px" }}
          eventKey="info"
          title={
            <>
              Your Student Loan <i className="bi bi-pencil-square"></i>
            </>
          }>
          <hr />
          <b>Student Loan Balance </b>
          <br />
          <br />
          <InputButtons
            value={{
              labels: [
                "Enter Student Loan Balance",
                "I don't know my Student Loan Balance",
              ],
              dataName: props.studentData.setLoanSize,
              name: "setLoanSize",
              data: props,
              handleInput: handleInput,
            }}
          />
          <br />
          <br />
          {props.studentData.setLoanSize !== "checked" &&
          props.studentData.loanByYear !== "checked" ? (
            <>
              <InputTheme
                value={{
                  disabledText:
                    props.studentData.setLoanSize === "checked"
                      ? props.studentData.loanByYear === "checked"
                        ? "You need to uncheck Manually Set Current Loan Balance and Set Tuition & Maintenance by Year in the Advanced Settings before entering your tuition loan."
                        : "You need to uncheck Manually Set Current Loan Balance in the Advanced Settings before entering your tuition loan."
                      : "You need to uncheck Set Tuition & Maintenance by Year in the Advanced Settings before entering your tuition loan.",
                  disabled:
                    props.studentData.setLoanSize === "checked" ||
                    props.studentData.loanByYear === "checked"
                      ? true
                      : false,
                  label: "Tuition Fee Loan (Per Year at Uni) ",
                  prepend: "£",
                  dataName: props.studentData.tuitionFee,
                  step: 50,
                  type: "number",
                  name: "tuitionFee",
                  data: props,
                  append: "",
                  handleInput: handleInput,
                  handleKeyUp: handleKeyUp,
                }}
              />
              <InputTheme
                value={{
                  disabledText:
                    props.studentData.setLoanSize === "checked"
                      ? props.studentData.loanByYear === "checked"
                        ? "You need to uncheck Manually Set Current Loan Balance and Set Tuition & Maintenance by Year in the Advanced Settings before entering your maintenance loan."
                        : "You need to uncheck Manually Set Current Loan Balance in the Advanced Settings before entering your maintenance loan."
                      : "You need to uncheck Set Tuition & Maintenance by Year in the Advanced Settings before entering your maintenance loan.",
                  disabled:
                    props.studentData.setLoanSize === "checked" ||
                    props.studentData.loanByYear === "checked"
                      ? true
                      : false,
                  label: "Maintenance Loan (Per Year at Uni) ",
                  prepend: "£",
                  dataName: props.studentData.maintenance,
                  step: 50,
                  type: "number",
                  name: "maintenance",
                  data: props,
                  append: "",
                  handleInput: handleInput,
                  handleKeyUp: handleKeyUp,
                }}
              />
            </>
          ) : null}
          {props.studentData.setLoanSize !== "checked" ? (
            <InputBox
              value={{
                onClick: true,
                disabledText:
                  "You cannot set tuition and maintenance by year if you are already manually setting current loan balance.",
                disabled:
                  props.studentData.setLoanSize === "checked" ? true : false,
                label: "Set fees for each year individually ",
                prepend: "",
                dataName: props.studentData.loanByYear,
                type: "checkbox",
                name: "loanByYear",
                data: props,
                append: "",
                handleInput: handleInput,
                handleKeyUp: handleKeyUp,
              }}
              showModal={showModal}
              setShowModal={setShowModal}
            />
          ) : null}
          {props.studentData.setLoanSize === "unchecked" &&
          props.studentData.loanByYear === "checked" ? (
            <Button
              size="sm"
              variant="outline-primary"
              onClick={() => {
                setShowModal(true);
              }}>
              Edit Tuition and Maintenance By Year Values
            </Button>
          ) : null}
          {props.studentData.setLoanSize === "checked" ? (
            <InputTheme
              value={{
                label: "Current Student Debt Total ",
                prepend: "£",
                dataName: props.studentData.currentDebt,
                step: 50,
                type: "number",
                name: "currentDebt",
                data: props,
                append: "",
                handleInput: handleInput,
                handleKeyUp: handleKeyUp,
              }}
            />
          ) : null}{" "}
          <hr />
          <b>Course Details </b>
          <br />
          <br />
          <Form>
            <InputTheme
              value={{
                label: "Course Start Year ",
                prepend: "",
                dataName: props.studentData.courseStartYear,
                step: 1,
                type: "number",
                name: "courseStartYear",
                data: props,
                append: "",
                focus: false,
                handleInput: handleInput,
                handleKeyUp: handleKeyUp,
              }}
            />
            <InputTheme
              value={{
                label: "Course Duration ",
                prepend: "",
                dataName: props.studentData.courseLength,
                dataCheck:
                  parseInt(props.studentData.courseLength) < 1 ||
                  parseInt(props.studentData.courseLength) > 20,
                dataCheckText: "You need to enter a value between 1 and 20.",
                step: 1,
                type: "number",
                name: "courseLength",
                data: props,
                append: "Years",
                handleInput: handleInput,
                handleKeyUp: handleKeyUp,
              }}
            />
            <InputCourse value={{ data: props }} />
            <hr />
            <b>Loan Plan </b>
            <br />
            <br />
            <InputSelect
              value={{
                label: "Student Loan Plan ",
                prepend: "",
                dataName: props.studentData.loanPlan,
                type: "select",
                name: "loanPlan",
                data: props,
                append: "",
                handleInput: handleInput,
                handleKeyUp: handleKeyUp,
              }}
              showModal={showModal}
              setShowModal={setShowModal}
            />
            <hr />
            <b>Salary </b>
            <br />
            <br />
            <InputTheme
              value={{
                label:
                  props.studentData.setLoanSize === "unchecked"
                    ? "Pre-Tax Salary after Graduating "
                    : "Current Pre-Tax Salary ",
                prepend: "£",
                dataName: props.studentData.salary,
                step: 50,
                type: "number",
                name: "salary",
                data: props,
                append: "",
                handleInput: handleInput,
                handleKeyUp: handleKeyUp,
              }}
            />
            {props.inUse.comparing ? null : (
              <HintDismissable
                p={
                  <>
                    {" "}
                    <i className="bi bi-info-circle"></i> Disclaimer: This tool
                    does not consitute financial advice. We do not recommend
                    taking actions based on the results of this tool; always do
                    your own research first, and check a trusted source such as
                    the UK Government website. Note that the tool makes many
                    assumptions and so the results could be inaccurate; for
                    example, Government policy could change and so the
                    assumptions in the tool may become outdated. We can't
                    guarantee to be perfect, so do note you use the information
                    at your own risk and we can't accept liability if things go
                    wrong. Visit{" "}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      style={{ color: "DarkBlue" }}
                      href="https://www.gov.uk/repaying-your-student-loan">
                      https://www.gov.uk/repaying-your-student-loan
                    </a>{" "}
                    to find out more information about UK student loans.
                  </>
                }
                variant="warning"
                className="small"
              />
            )}{" "}
          </Form>
        </Tab>
        <Tab
          style={{ margin: "10px 3px 0px 3px" }}
          eventKey="advanced"
          title={
            <>
              Advanced Options <i className="bi bi-gear-wide-connected"></i>
            </>
          }>
          <hr />
          <HintDismissable
            heading={null}
            p={
              <>
                {" "}
                <i className="bi bi-info-circle"></i> Advanced users can edit
                the assumptions made in the calculator here.
              </>
            }
            variant={"warning"}
            className={"small"}
          />
          <Form>
            <InputTheme
              value={{
                label: "Monthly Overpayment ",
                prepend: "£",
                dataName: props.studentData.overpayment,
                step: 10,
                type: "number",
                name: "overpayment",
                data: props,
                append: "",
                handleInput: handleInput,
                handleKeyUp: handleKeyUp,
              }}
            />{" "}
            <hr />
            <InputTheme
              value={{
                label: "RPI (Inflation) Rate ",
                prepend: "",
                dataName: props.studentData.rpi,
                step: 0.1,
                type: "number",
                name: "rpi",
                data: props,
                append: "%",
                handleInput: handleInput,
                handleKeyUp: handleKeyUp,
              }}
            />
            <InputTheme
              value={{
                label: "Your Salary Growth ",
                prepend: "",
                dataName: props.studentData.salaryGrowth,
                step: 0.1,
                type: "number",
                name: "salaryGrowth",
                data: props,
                append: "%",
                handleInput: handleInput,
                handleKeyUp: handleKeyUp,
              }}
            />
            <InputTheme
              value={{
                label: "UK Average Earnings Growth ",
                prepend: "",
                dataName: props.studentData.earningsGrowth,
                step: 0.1,
                type: "number",
                name: "earningsGrowth",
                data: props,
                append: "%",
                handleInput: handleInput,
                handleKeyUp: handleKeyUp,
              }}
            />
            <InputTheme
              value={{
                label: "Bank of England Base (Interest) Rate ",
                prepend: "",
                dataName: props.studentData.baseRate,
                step: 0.1,
                type: "number",
                name: "baseRate",
                data: props,
                append: "%",
                handleInput: handleInput,
                handleKeyUp: handleKeyUp,
              }}
            />{" "}
            <hr />
            <InputTheme
              value={{
                label: "Current Payment Threshold ",
                prepend: "£",
                dataName: props.studentData.currentPaymentFloor,
                step: 1,
                type: "number",
                name: "currentPaymentFloor",
                data: props,
                append: "",
                handleInput: handleInput,
                handleKeyUp: handleKeyUp,
              }}
            />
            <InputTheme
              value={{
                label: "Current Interest Rate Ceiling ",
                prepend: "£",
                dataName: props.studentData.currentRateCeiling,
                step: 1,
                type: "number",
                name: "currentRateCeiling",
                data: props,
                append: "",
                handleInput: handleInput,
                handleKeyUp: handleKeyUp,
              }}
            />{" "}
            <hr />
            <InputBox
              value={{
                label: "Use Historical Data ",
                prepend: "",
                dataName: props.studentData.useActual,
                type: "checkbox",
                name: "useActual",
                data: props,
                append: "",
                handleInput: handleInput,
                handleKeyUp: handleKeyUp,
              }}
            />
            <InputBox
              value={{
                label: "Increase Starting Salary with Inflation ",
                prepend: "",
                dataName: props.studentData.inflateSalary,
                type: "checkbox",
                name: "inflateSalary",
                data: props,
                append: "",
                handleInput: handleInput,
                handleKeyUp: handleKeyUp,
              }}
            />
          </Form>
        </Tab>
      </Tabs>

      <br />
      <div className="buttonFlex">
        <NavLink
          className="resultsScrollToEvent"
          to={
            props.studentData.courseLength + props.studentData.courseStartYear >
              props.studentData.currentYear &&
            props.studentData.setLoanSize === "checked"
              ? ""
              : "resultsScrollToEvent"
          }
          spy={true}
          smooth={true}
          duration={500}>
          <Button
            disabled={
              props.studentData.courseLength +
                props.studentData.courseStartYear >
                props.studentData.currentYear &&
              props.studentData.setLoanSize === "checked"
            }
            className="inputButton"
            variant={props.inUse.comparing ? "warning" : "primary"}
            type="button"
            name="submit"
            value="Calculate"
            onClick={(e) => {
              handleSubmit(e, props);
            }}>
            {props.inUse.comparing ? (
              props.inUse.recalculate[1] ? (
                <>
                  Recalculate Comparison{" "}
                  <i className="bi bi-arrow-right-circle"></i>{" "}
                </>
              ) : (
                <>
                  Calculate Comparison{" "}
                  <i className="bi bi-arrow-right-circle"></i>{" "}
                </>
              )
            ) : props.inUse.recalculate[0] ? (
              <>
                {" "}
                Recalculate <i className="bi bi-arrow-right-circle"></i>
              </>
            ) : (
              <>
                {" "}
                Calculate <i className="bi bi-arrow-right-circle"></i>
              </>
            )}
          </Button>
        </NavLink>
        <Button
          className="inputButton"
          variant="outline-primary"
          type="button"
          name="reset"
          value="ResetInputs"
          onClick={(e) => {
            handleReset(e, props);
          }}>
          Reset Inputs <i className="bi bi-arrow-repeat"></i>
        </Button>
      </div>
    </div>
  );
};

export default InputPanel;
