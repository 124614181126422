import React from "react";
import { Modal, Button, Badge, Form, Row, Col } from "react-bootstrap";
import InputTheme from "../elements/InputTheme";

/// REF
//  showModal={showModal}
// setShowModal={setShowModal}
// handleInput={handleInput}
// handleLoanByYearInput={handleLoanByYearInput}
// data={props}

const ModalLoanInput = (props) => {
  let value = props.value;

  let FormInputs = value.arr.map((val, index) => {
    return (
      <div key={index}>
        <Form.Label>
          Academic Year: {val}-{val + 1}
        </Form.Label>
        <Row>
          <Col>
            <InputTheme
              value={{
                label: "Tuition Fee Loan",
                prepend: "£",
                dataName: value.data.studentData[`loanByYearTuition${index}`],
                step: 1,
                type: "number",
                name: `loanByYearTuition${index}`,
                data: value.data,
                append: "",
                focus: false,
                handleInput: value.handleInput,
                handleKeyUp: value.handleKeyUp,
                loanByYear: true,
              }}
            />
          </Col>{" "}
          <Col>
            <InputTheme
              value={{
                label: "Maintenance Loan",
                prepend: "£",
                dataName:
                  value.data.studentData[`loanByYearMaintenance${index}`],
                step: 1,
                type: "number",
                name: `loanByYearMaintenance${index}`,
                data: value.data,
                append: "",
                focus: false,
                handleInput: value.handleInput,
                handleKeyUp: value.handleKeyUp,
                loanByYear: true,
              }}
            />
          </Col>
        </Row>
        <hr />
      </div>
    );
  });
  return (
    <>
      <Modal
        show={props.showModal}
        onHide={() => {
          props.setShowModal(false);
        }}
        centered
        size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            <h2>Set Tuition Fee & Maintenance Loan by Year</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{FormInputs}</Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              props.setShowModal(false);
            }}>
            Set Loan Values <i className="bi bi-arrow-right-circle"></i>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalLoanInput;
