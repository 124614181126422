import AdSense from "react-adsense";

const faqData = {
  howDoesItWork: {
    tags: [],
    title: "How does the calculator work?",
    content: (
      <>
        <br />
        <p>
          If you are a student with a plan 1, 2, 4, 5 or postgraduate student
          loan, you only make repayments if your pre-tax salary is above the
          repayment threshold. Your repayment totals 9% of your earnings above
          the threshold on plan 1, 2, 4 or 5 or 6% of you earnings above the
          threshold if you are on a postgraduate plan.
        </p>
        <p>
          This calculator uses the inputs about your student loan debt and a
          number of assumptions to calculate your repayments each year. The
          calculator adjusts for future increases in your income, changes to the
          thresholds and the interest that accrues on your loan.
        </p>
      </>
    ),
  },

  whenDoYouRepay: {
    tags: [],
    title: "When do you start repaying?",
    content: (
      <>
        The earliest you’ll start repaying is either:
        <br />
        <br />
        <ul>
          <li>The April after you leave your course</li>
          <li>
            The April 4 years after the course started, if you’re studying
            part-time
          </li>
        </ul>
        You only make repayments if you earn more than the salary threshold for
        your loan plan. This means your repayments automatically stop if either:
        <br />
        <br />
        <ul>
          <li>You stop working</li>
          <li>Your income goes below the threshold</li>
          <li>You repay your student loan balance</li>
          <li>Your student loan balance is written off</li>
        </ul>
      </>
    ),
    reference: "https://www.gov.uk/repaying-your-student-loan",
  },

  advert1: {
    tags: [],
    title: "",
    content: (
      <AdSense.Google
        client="ca-pub-4419428690072900"
        slot="4403622517"
        style={{
          display: "block",
          textAlign: "center",
          minWidth: "250px",
        }}
        layout="in-article"
        format="fluid"
      />
    ),
    reference: "",
  },

  plan1loans: {
    tags: [],
    title: "What are Plan 1 Loans?",
    content: (
      <>
        Plan 1 Loans are a type of UK student loan. You’re on Plan 1 if you’re:
        <br />
        <br />
        <ul>
          <li>
            An English or Welsh student who started an undergraduate course
            anywhere in the UK before 1 September 2012
          </li>
          <li>
            A Northern Irish student who started an undergraduate or
            postgraduate course anywhere in the UK on or after 1 September 1998.
            Northern Ireland still uses Plan 1 loans.
          </li>
          <li>
            An EU student who started an undergraduate course in England or
            Wales on or after 1 September 1998, but before 1 September 2012
          </li>
          <li>
            An EU student who started an undergraduate or postgraduate course in
            Northern Ireland on or after 1 September 1998
          </li>
        </ul>
      </>
    ),
    reference: "https://www.gov.uk/repaying-your-student-loan",
  },

  plan2loans: {
    tags: [],
    title: "What are Plan 2 Loans?",
    content: (
      <>
        Plan 2 Loans are a type of UK student loan introduced in 2012. You’re on
        Plan 2 if you’re:
        <br />
        <br />
        <ul>
          <li>
            An English or Welsh student who started an undergraduate course
            anywhere in the UK on or after 1 September 2012
          </li>
          <li>
            An EU student who started an undergraduate course in England or
            Wales on or after 1 September 2012
          </li>
          <li>
            Someone who took out an Advanced Learner Loan on or after 1 August
            2013
          </li>
        </ul>
      </>
    ),
    reference: "https://www.gov.uk/repaying-your-student-loan",
  },

  plan4loans: {
    tags: [],
    title: "What are Plan 4 Loans?",
    content: (
      <>
        Plan 4 Loans are a type of UK student loan introduced in 1998, for
        Scottish Students or EU Students studing in Scotland. You’re on Plan 4
        if you’re:
        <br />
        <br />
        <ul>
          <li>
            A Scottish student who started an undergraduate or postgraduate
            course anywhere in the UK on or after 1 September 1998
          </li>
          <li>
            An EU student who started an undergraduate or postgraduate course in
            Scotland on or after 1 September 1998
          </li>
        </ul>
      </>
    ),
    reference: "https://www.gov.uk/repaying-your-student-loan",
  },

  plan5loans: {
    tags: [],
    title: "What are Plan 5 Loans?",
    content: (
      <>
        Plan 5 Loans are a newer type of UK student loan introduced in 2023, for
        English Students. You’re on Plan 5 if you’re:
        <br />
        <br />
        <ul>
          <li>
            An English or Welsh student who started an undergraduate course
            anywhere in the UK on or after 1 August 2023
          </li>
        </ul>
      </>
    ),
    reference: "https://www.gov.uk/repaying-your-student-loan",
  },

  repaymentThresholds: {
    tags: [],
    title: "What are the Repayment Thresholds?",
    content: (
      <>
        You only make repayments towards your student loan balance if your
        salary is above the repayment threshold. The threshold depends on the
        loan plan you are on and the thresholds are subject to change every
        year. The following thresholds are correct for April 2022 to April 2023.
        <br />
        <br />
        <b>If you have a Plan 1 student loan: </b>
        <br />
        You’ll only repay when your income is over £423 a week, £1,834 a month
        or £22,015 a year (before tax and other deductions). <br />
        <br />
        <b>If you have a Plan 2 student loan:</b>
        <br />
        You’ll only repay when your income is over £524 a week, £2,274 a month
        or £27,295 a year (before tax and other deductions). <br />
        <br />
        <b>If you have a Plan 4 student loan:</b>
        <br />
        You’ll only repay when your income is over £532 a week, £2,305 a month
        or £27,660 a year (before tax and other deductions).
        <br />
        <b>If you have a Plan 5 student loan:</b>
        <br />
        You’ll only repay when your income is over £480 a week, £2,083 a month
        or £25,000 a year (before tax and other deductions).
        <br />
        <br /> <b>If you’re on a Postgraduate Loan repayment plan:</b>
        <br /> If you took out a Master’s Loan or a Doctoral Loan, you’ll only
        repay when your income is over £403 a week, £1,750 a month or £21,000 a
        year (before tax and other deductions).
      </>
    ),
    reference: "https://www.gov.uk/repaying-your-student-loan",
  },
  loanVsGrant: {
    tags: [],
    title: "What is the difference between a loan and a grant/bursary?",
    content: (
      <>
        You only make repayments on:
        <br />
        <br />
        <ul>
          <li>Tuition Fee Loans</li>
          <li>Maintenance Loans</li>
          <li>Postgraduate Loans</li>
        </ul>
        You don't need to make repayments for grants or bursaries.
      </>
    ),
    reference: "https://www.gov.uk/repaying-your-student-loan",
  },

  advert2: {
    tags: [],
    title: "",
    content: (
      <AdSense.Google
        client="ca-pub-4419428690072900"
        slot="4403622517"
        style={{
          display: "block",
          textAlign: "center",
          minWidth: "250px",
        }}
        layout="in-article"
        format="fluid"
      />
    ),
    reference: "",
  },

  repayQuickly: {
    tags: [],
    title: "Can I repay more quickly?",
    content: (
      <>
        If you plan on repaying your student loan in full and you want to
        accumulate as little interest as possible, you can manually make
        payments (visit the reference site to find out more). It is worth making
        careful calculations before overpaying, particularly for those with Plan
        2 loans. Other than the very high earners, the high interest rate means
        most people with Plan 2 loans are better off waiting for the 30 year
        write-off period than they are trying to pay down the debt. You can
        check whether it would be worthwhile in the overpayment box in the
        results section.
      </>
    ),
    reference: "https://www.gov.uk/repaying-your-student-loan/how-you-repay",
  },

  shackledWithDebt: {
    tags: [],
    title: "Am I shackled with debt for the next 25-30 years?",
    content: (
      <>
        No, not really. When tuition fees were raised in 2012 there was
        significant political posturing about how underprivileged students would
        no longer be able to afford university and that those who did attend
        would be in debt for the rest of their lives. <br />
        <br />
        In reality the raising of tuition fees and the new student loan system
        is more of a psychological barrier to entry than a financial one;
        student loans in the UK are effectively a graduate tax. For most people
        that tax will last 20+ years. You will pay a percentage of your income,
        just like a tax, and you will not be obliged to make monthly repayments
        like you would with a regular commercial loan. This is very different
        from student loans in the USA, which are commercial loans. <br />
        <br />
        In practice, the main difference between a Plan 1 and a Plan 2 student
        loan is that Plan 2 loans are attached to such large debts that it
        becomes a greater psychological burden, as well as more difficult to pay
        off in full due to the higher interest rates. Many more people with Plan
        2 loans will have to wait for the 30 year write-off period before they
        are free from the extra 9% tax. However, Plan 2 students have the
        advantage of only have to make repayments on earnings above a higher
        threshold than those on Plan 1.
      </>
    ),
    reference: "https://www.gov.uk/repaying-your-student-loan",
  },
};

export default faqData;
