import { InputGroup, Form } from "react-bootstrap";
import Popover from "./popover.js";
import inputData from "../data/inputData";

const InputSelect = (props) => {
  let value = props.value;
  return (
    <>
      <Form.Label>
        {value.label}
        {inputData.popoverTitle[value.name] === "" ? null : (
          <Popover
            popoverTitle={inputData.popoverTitle[value.name]}
            popoverContent={inputData.popoverContent[value.name]}
          />
        )}
      </Form.Label>
      <InputGroup className="mb-3">
        <Form.Control
          as="select"
          disabled={value.disabled}
          type={value.type}
          name={value.name}
          value={value.dataName}
          onChange={(e) => {
            value.handleInput(e, value.data);
            console.log(e, value.data);
          }}
          onKeyUp={(e) => {
            value.handleKeyUp(e, value.data);
          }}>
          <option
            value="plan1"
            disabled={
              value.data.studentData.courseStartYear > 2011 ? true : false
            }>
            Plan 1 (England & Wales Pre-2012)
          </option>
          <option value="plan1NI">Plan 1 (Northern Ireland Students)</option>
          <option
            disabled={
              value.data.studentData.courseStartYear < 2011 ? true : false
            }
            value="plan2">
            Plan 2 (England & Wales 2012+)
          </option>
          <option
            value="planPostgrad"
            disabled={
              value.data.studentData.courseStartYear < 2016 ? true : false
            }>
            Postgraduate Loan
          </option>
          <option value="plan4">Plan 4 (Scottish Students)</option>{" "}
          <option
            value="plan5"
            disabled={
              value.data.studentData.courseStartYear < 2023 ? true : false
            }>
            Plan 5 (England 2023+)
          </option>
        </Form.Control>
      </InputGroup>
      {/* If the start year is pre-2012, the student couldn't take out a plan 2, 5 or postgraduate loan. */}
      {value.data.studentData.courseStartYear < 2012 ? (
        <>
          {" "}
          <Form.Text className="text-muted">
            <i className="bi bi-exclamation-circle-fill"></i> Plan 2 Loans were
            not available for those starting a course before 2012. Plan 5 Loans
            were not available for those starting a course before 2023.
            Postgraduate Loans were not available for those starting a course
            before 2016. Please change the course start year if you want to
            select Plan 2, Plan 5 or Postgraduate Loans. Find out more about
            Loan Plans in our <a href="/faqs">FAQs</a> or on{" "}
            <a
              href="https://www.gov.uk/repaying-your-student-loan/which-repayment-plan-you-are-on"
              target="_blank"
              rel="noreferrer">
              www.gov.uk
            </a>
          </Form.Text>{" "}
        </>
      ) : null}
      {/* If the start year is post-2012 but pre-2016, the student couldn't take out a plan 1, 5 or postgraduate loan. */}
      {value.data.studentData.courseStartYear > 2011 &&
      value.data.studentData.courseStartYear < 2016 ? (
        <>
          {" "}
          <Form.Text className="text-muted">
            <i className="bi bi-exclamation-circle-fill"></i> Plan 1 Loans were
            not available for those starting a course after 2011. Plan 5 Loans
            were not available for those starting a course before 2023.
            Postgraduate Loans were not available for those starting a course
            before 2016. Please change the course start year if you want to
            select Plan 1, Plan 5 or Postgraduate Loans. Find out more about
            Loan Plans in our <a href="/faqs">FAQs</a> or on{" "}
            <a
              href="https://www.gov.uk/repaying-your-student-loan/which-repayment-plan-you-are-on"
              target="_blank"
              rel="noreferrer">
              www.gov.uk
            </a>
          </Form.Text>{" "}
        </>
      ) : null}
      {/* If the start year is post-2016, but pre-2023 the student couldn't take out a plan 1 or 5 loan. */}
      {value.data.studentData.courseStartYear > 2015 &&
      value.data.studentData.courseStartYear < 2023 ? (
        <>
          {" "}
          <Form.Text className="text-muted">
            <i className="bi bi-exclamation-circle-fill"></i> Plan 1 Loans were
            not available for those starting a course after 2012. Plan 5 Loans
            were not available for those starting a course before 2023. Please
            change the course start year if you want to select Plan 1 or Plan 5
            Loans. Find out more about Loan Plans in our{" "}
            <a href="/faqs">FAQs</a> or on{" "}
            <a
              href="https://www.gov.uk/repaying-your-student-loan/which-repayment-plan-you-are-on"
              target="_blank"
              rel="noreferrer">
              www.gov.uk
            </a>
          </Form.Text>{" "}
        </>
      ) : null}
      {/* If the start year is post-2022, the student couldn't take out a plan 1 loan. */}
      {value.data.studentData.courseStartYear > 2022 ? (
        <>
          {" "}
          <Form.Text className="text-muted">
            <i className="bi bi-exclamation-circle-fill"></i> Plan 1 Loans were
            not available for those starting a course after 2012. Please change
            the course start year if you want to select Plan 1 Loans. Find out
            more about Loan Plans in our <a href="/faqs">FAQs</a> or on{" "}
            <a
              href="https://www.gov.uk/repaying-your-student-loan/which-repayment-plan-you-are-on"
              target="_blank"
              rel="noreferrer">
              www.gov.uk
            </a>
          </Form.Text>{" "}
        </>
      ) : null}
    </>
  );
};

export default InputSelect;
