import React from "react";

/**
 * Renders the aboutCalcPanel component.
 *
 * @returns {JSX.Element} The rendered aboutCalcPanel component.
 */
const aboutCalcPanel = () => {
  return (
    <div className="panel aboutPanel">
      <div>
        <h5>About the Calculator</h5>
        <p>
          <br />
          The UK Student Loan system is complicated.
          <br />
          <br />
          The UK student loan debt you accumulate isn't debt in the traditional
          sense; you are only required to make repayments when you earn above a
          salary threshold. And, if you haven't fully repaid your debt 30 years
          after graduating from University, the remaining balance is written
          off. If you are on a Plan 1 loan, the debt is written off after 25
          years.
          <br />
          <br />
          In practice, this means that only a small percentage of current and
          future students are set to fully repay their student loan balance
          before it is written off. If you fall into this category, making
          overpayments to pay off your student loan early could cost you,
          because you are due to have your balance written off in the future (at
          no cost to you).
          <br />
          <br />
          However, if you are a student who took out a small amount of student
          loan debt, or those who are high earners, there is a chance you may
          still fully repay your student loan balance before the balance is
          written off. For these few, overpaying may be a good option.
          <br />
          <br />
          To help inform people about whether overpayments could be a good idea,
          I built www.studentcalc.co.uk, which will help you better understand
          the options you have and whether or not overpaying could be a good
          idea.
          <br />
          <br />
          You can use the comparison feature to compare scenarios (such as
          overpaying on your loan or taking out more student loan debt), to see
          if it is likely to increase or decrease your repayments over time.
        </p>
      </div>
    </div>
  );
};

export default aboutCalcPanel;
