let historicalData = {
  rpi: {
    2024: 5.3,
    2023: 13.4,
    2022: 2.0,
    2021: 2.1,
    2020: 2.6,
    2019: 2.4,
    2018: 3.3,
    2017: 3.1,
    2016: 1.6,
    2015: 0.9,
    2014: 2.5,
    2013: 3.3,
    2012: 3.6,
  },
  currentPaymentFloor: {
    2024: 27295,
    2023: 27295,
    2022: 27295,
    2021: 27295,
    2020: 26575,
    2019: 25725,
    2018: 25000,
    2017: 21000,
    2016: 21000,
    2015: 21000,
    2014: 21000,
    2013: 21000,
    2012: 21000,
  },
  currentRateCeiling: {
    2023: 49130,
    2022: 49130,
    2021: 49130,
    2020: 47835,
    2019: 46305,
    2018: 45000,
    2017: 41000,
    2016: 41000,
    2015: 41000,
    2014: 41000,
    2013: 41000,
    2012: 41000,
  },
  plan1Floor: {
    2000: 10000,
    2001: 10000,
    2002: 10000,
    2003: 10000,
    2004: 10000,
    2005: 15000,
    2006: 15000,
    2007: 15000,
    2008: 15000,
    2009: 15000,
    2010: 15000,
    2011: 15000,
    2012: 15795,
    2013: 16365,
    2014: 16910,
    2015: 17335,
    2016: 17495,
    2017: 17775,
    2018: 18330,
    2019: 18935,
    2020: 19390,
    2021: 19895,
    2022: 20195,
    2023: 22015,
    2024: 22015,
  },
  plan4Floor: {
    2000: 10000,
    2001: 10000,
    2002: 10000,
    2003: 10000,
    2004: 10000,
    2005: 15000,
    2006: 15000,
    2007: 15000,
    2008: 15000,
    2009: 15000,
    2010: 15000,
    2011: 15000,
    2012: 15795,
    2013: 16365,
    2014: 16910,
    2015: 17335,
    2016: 17495,
    2017: 17775,
    2018: 18330,
    2019: 18935,
    2020: 19390,
    2021: 25000,
    2022: 25375,
    2023: 27660,
    2024: 27660,
  },
  plan5Floor: {
    2023: 25000,
  },
  plan1Rate: {
    2023: 5,
    2022: 1.25,
    2021: 1.1,
    2020: 1.1,
    2019: 1.7,
    2018: 1.75,
    2017: 1.35,
    2016: 1.25,
    2015: 0.9,
    2014: 1.5,
    2013: 1.5,
    2012: 1.5,
    2011: 1.5,
    2010: 1.5,
    2009: 1.5,
    2008: 3.4,
    2007: 4.8,
    2006: 2.4,
    2005: 3.2,
    2004: 2.6,
    2003: 3.1,
    2002: 1.3,
    2001: 2.3,
    2000: 2.6,
    1999: 2.1,
    1998: 3.5,
  },
  defaultPlanFloor: {
    plan1: 20195,
    plan1NI: 20195,
    plan2: 27295,
    plan4: 25375,
    plan5: 25000,
    planPostgrad: 21000,
  },
  interestCap: {
    plan1: { 2024: 6.25 },
    plan1NI: null,
    plan2: {
      2023: 6.9,
      2024: 7.6,
    },
    plan4: { 2024: 6.25 },
    plan5: { 2024: 7.6 },
    planPostgrad: {
      2023: 6.9,
      2024: 7.6,
    },
  },
};

export default historicalData;
