import { Form } from "react-bootstrap";

const InputCourse = (props) => {
  let data = props.value.data;
  if (
    data.studentData.courseLength + data.studentData.courseStartYear >
      data.studentData.currentYear &&
    data.studentData.setLoanSize === "checked"
  ) {
    return (
      <>
        <Form.Text className="text-muted">
          <i className="bi bi-exclamation-circle-fill"></i> You have set a
          course start year and course length that suggests you have not yet
          completed University. You cannot set your current student debt total
          because it will be set to change over your time at University. Please
          use the 'I don't know my student loan balance' button instead, or
          adjust the course start year or course length.
        </Form.Text>
      </>
    );
  } else {
    return null;
  }
};

export default InputCourse;
