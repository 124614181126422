const resultsPopoverData = {
  dataTable: (
    <ul>
      <li>View annual inputs and results in the data table.</li>
      <li>Some of the columns allow for manual input, adjusted by year.</li>
      <li>
        If you choose to set data by year, we will set the next year's value
        automatically, and increase it in line with inflation.
      </li>
    </ul>
  ),
  annualOverpayment: (
    <ul>
      <li>
        If you want to, you can see what happens if you made extra repayments on
        top of your standard repayments.
      </li>
      <li>
        We assume that you increase the overpayment amount annually in line with
        inflation.
      </li>
    </ul>
  ),
  "Total Repayments": (
    <ul>
      <li>
        The total repayments box shows you how much you will repay for your
        student loan in total over your lifetime.
      </li>
      <li>
        Note that the total amount you will repay is highly dependent on many
        factors, including your salary and the repayment threshold.
      </li>
    </ul>
  ),
  "IFS Report": (
    <span>
      The average University undergraduate will earn an estimated £115,000
      (women), and £145,000 (men) more in their lifetime in real terms than if
      they didn't attend University. This figure accounts for paying higher
      taxes and national insurance.
      <br />
      <br />
      Reference:
      <br />
      <i>
        https://ifs.org.uk/uploads/R167-The-impact-of-undergraduate-degrees-on-lifetime-earnings.pdf
      </i>
    </span>
  ),
  "Debt on Graduation": (
    <ul>
      <li>
        This panel shows the total amount of debt you will have upon leaving
        University.
      </li>
      <li>
        Note that whilst this amount of debt may seem psychologically large, the
        total cost of going to University is actually often unrelated to this
        debt total.
      </li>
      <li>
        If you have not cleared your debt after 25 years (if you are on Plan 1),
        30 years (if you are on Plan 2, 4 or Postgrad Plans), or 40 years (if
        you are on Plan 5) then the debt will be wiped by the Government at no
        cost to you.
      </li>
    </ul>
  ),
  "Remaining balance before being written off": (
    <ul>
      <li>
        If you have not cleared your debt after 25 years (if you are on Plan 1),
        30 years (if you are on Plan 2, 4 or Postgrad Plans), or 40 years (if
        you are on Plan 5) then the debt will be wiped by the Government at no
        cost to you.
      </li>
      <li>
        For many graduates, this means that a large portion of student loan debt
        is never repaid - it is forgiven by the government.
      </li>
      <li>
        This also means that making overpayments on your student loan debt can
        be a huge mistake. If your debt is set to be wiped in the future anyway,
        then any overpayments you make are wasted.
      </li>
    </ul>
  ),
  "Years to clear debt": (
    <ul>
      <li>
        This panel shows you how many years it could take for you to clear your
        student loan debt.
      </li>
      <li>
        If you have not cleared your debt after 25 years (if you are on Plan 1),
        30 years (if you are on Plan 2, 4 or Postgrad Plans), or 40 years (if
        you are on Plan 5) then the debt will be wiped by the Government at no
        cost to you.
      </li>
      <li>
        Please note that this value is at best an estimate and depends on many
        factors.
      </li>
    </ul>
  ),
  "Overpayment Rate of Return": (
    <ul>
      <li>
        This panel shows you an estimate of the rate of return you would receive
        on overpayments made towards your student loan debt.
      </li>
      <li>
        Please note that this value is at best an estimate and depends on many
        factors.
      </li>
      <li>
        Warning: The value shown is nominal, meaning that inflation is not taken
        into account. This means that if the rate of return shown here on your
        overpayments is 3%, and inflation this year is 5%, then the overpayments
        you make will actually have a -2% real rate of return.
      </li>
    </ul>
  ),
};

export default resultsPopoverData;
