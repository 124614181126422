/**
 * Calculates mortgage details based on the provided mortgage data.
 *
 * @param {Object} mortgageData - The mortgage data.
 * @param {number} mortgageData.principal - The principal amount of the mortgage.
 * @param {number} mortgageData.rate - The annual interest rate of the mortgage.
 * @param {number} mortgageData.term - The term of the mortgage in years.
 * @returns {Object} An object containing the calculated mortgage details.
 */

function mortgageCalculator({ principal, rate, term, mortgageType }) {
  // INITIALIZE VARIABLES //

  const percentRate = rate / 100; // Convert the rate to a percentage
  const monthlyRate = percentRate / 12; // Calculate the monthly interest rate
  const numberOfPayments = term * 12; // Convert the term from years to months

  let monthlyRepayment = 0;
  let totalRepaid = 0;
  let totalInterestRepaid = 0;
  let monthlyInterestPayment = principal * monthlyRate;

  if (mortgageType === "repayment") {
    // CALCULATE FOR REPAYMENT MORTGAGE //
    monthlyRepayment =
      (principal * (monthlyRate * (1 + monthlyRate) ** numberOfPayments)) /
      ((1 + monthlyRate) ** numberOfPayments - 1); // Calculate the monthly repayment amount using the formula for an annuity
    totalRepaid = monthlyRepayment * numberOfPayments; // Calculate the total amount repaid over the term of the mortgage
    totalInterestRepaid = totalRepaid - principal; // Calculate the total interest repaid over the term of the mortgage
  } else {
    // CALCULATE FOR INTEREST ONLY MORTGAGE //
    monthlyRepayment = Math.round(monthlyInterestPayment); // Calculate the monthly interest payment
    totalRepaid = monthlyInterestPayment * numberOfPayments + principal; // Calculate the total amount paid over the term of the mortgage
    totalInterestRepaid = monthlyInterestPayment * numberOfPayments; // Calculate the total interest paid over the term of the mortgage
  }

  // CALCULATE CUMULATIVE INTEREST PAID //

  // Initialize an array to store the cumulative interest paid each year
  const cumulativeInterestPaid = [];

  // Calculate the cumulative interest paid each year
  for (let i = 0; i < term + 1; i++) {
    const common_ratio = 1 + monthlyRate;
    const j = i * 12;

    // Calculate the remaining debt after each year
    const debt_n =
      principal * common_ratio ** j -
      (monthlyRepayment * (common_ratio ** j - 1)) / (common_ratio - 1);

    // Add the remaining debt for the current year to the array
    cumulativeInterestPaid.push({
      amount: Math.round(debt_n),
      year: i,
    });
  }

  // STORE SUMMARY DATA //

  // Store the original mortgage data
  const data = {};

  // Initialize an object to store the summary data
  let summaryData = {};

  // Store the calculated data in the summary object
  summaryData.monthlyInterestPayment = monthlyInterestPayment;
  summaryData.monthlyRepayment = monthlyRepayment;
  summaryData.totalRepaid = totalRepaid;
  summaryData.totalInterestRepaid = totalInterestRepaid;
  summaryData.cumulativeInterestPaid = cumulativeInterestPaid;

  // Return the original data and the summary data
  return { data: data, summaryData: summaryData };
}

// Export the mortgageCalculator function as the default export
export default mortgageCalculator;
