import inputData from "../data/inputData";

const handleValidate = (e, data) => {
  let output = Object.create({});
  Object.assign(output, data);
  let keyArray = Object.keys(inputData.popoverTitle);
  console.log("Data to validate", data);
  console.log("inputData max", inputData.max);
  keyArray.forEach((el) => {
    // Firstly, if data is evidently broken, output the default instead
    if (
      data[el] !== null &&
      data[el] !== undefined &&
      typeof inputData.default[el] === typeof data[el]
    ) {
      // Next, check how the data needs to be validated. If the data min is null, then it needs to be either checked or unchecked.
      if (inputData.min[el] === null) {
        data[el] === "checked" || data[el] === "unchecked"
          ? (output[el] = data[el])
          : (output[el] = inputData.default[el]);
        if (el === "courseLength") {
          console.log("courseLength has reached level data number-1");
        }
      }
      // If data min is undefined, it is the loan plan and doesn't need to be validated (it shouldn't face any general user issues).
      else if (inputData.min[el] === undefined) {
        output[el] = data[el];
        if (el === "courseLength") {
          console.log("courseLength has reached level data number0");
        }
      }
      // If the data min is a number, then we should check against max and min values.
      else {
        if (el === "courseLength") {
          console.log("courseLength has reached level data number1");
        }
        output[el] = data[el];
        if (el === "courseLength") {
          console.log(
            "courseLength has reached level data number2",
            output[el],
            data[el]
          );
        }
        if (data[el] > inputData.max[el] || data[el] < inputData.min[el]) {
          output[el] = inputData.default[el];
          if (el === "courseLength") {
            console.log(
              "courseLength has reached level data number3",
              output[el],
              data[el],
              data[el] > inputData.max[el],
              data[el] < inputData.min[el]
            );
          }
        } else {
          output[el] = data[el];
          if (el === "courseLength") {
            console.log(
              "courseLength has reached level data number4",
              output[el],
              data[el],
              data[el] > inputData.max[el],
              data[el] < inputData.min[el]
            );
          }
        }
      }
    } else {
      console.log(`${el} failed`);
      output[el] = inputData.default[el];
    }
  });

  if (output.loanByYear === "checked") {
    for (let i = 0; i < output.courseLength; i++) {
      if (output[`loanByYearTuition${i}`] === undefined) {
        output[`loanByYearTuition${i}`] = 0;
      }
      if (output[`loanByYearMaintenance${i}`] === undefined) {
        output[`loanByYearMaintenance${i}`] = 0;
      }
    }
  }
  console.log("Data validated", output);
  return output;
};

export default handleValidate;
