import React from "react";
import AdSense from "react-adsense";
import { Alert, Button } from "react-bootstrap";
import useWindowDimensions from "../elements/windowDimensions";

/**
 * Renders the ExplainerPanel component.
 * This component displays information about the UK Student Loan calculator,
 * including the potential student loan debt, repayment costs, and the time it
 * could take to pay off the loan.
 *
 * @returns {JSX.Element} The rendered ExplainerPanel component.
 */
function ExplainerPanel() {
  const { height, width } = useWindowDimensions();
  return (
    <div className="explainerPanel">
      {width > 600 ? (
        <>
          <h4>Calculate the real cost of your UK Student Loan</h4>
          <p>
            If you are an English, Welsh, Scottish or EU student with a UK
            Student Loan, you can use this calculator to find out:
          </p>
          <ul>
            <li>How much student loan debt you could graduate with</li>
            <li>Potential repayment costs per year</li>
            <li>How long it could take to pay off your student loan debt</li>
          </ul>
          <Alert variant="primary">
            <i className="bi bi-info-circle"></i> This calculator does not
            constitute financial advice.
          </Alert>
        </>
      ) : (
        <>
          <h6>
            <b>Calculate the real cost of your UK Student Loan</b>
          </h6>
          <p>
            If you are an English, Welsh, Scottish or EU student with a UK
            Student Loan, you can use this calculator to find out your potential
            student loan debt, repayment costs and how long it could take to pay
            off. Now updated to work for the new plan 5 loans.
          </p>
        </>
      )}
      {width > 600 ? (
        <>
          <AdSense.Google
            client="ca-pub-4419428690072900"
            slot="2744884467"
            style={{
              display: "block",
              textAlign: "center",
              minWidth: "250px",
              marginBottom: "12px",
            }}
            layout="display"
            format="auto"
          />
          <h5>How the calculator works</h5>
          <p>
            If you are a student with a Plan 1, 2, 4, 5 or postgraduate student
            loan, you only make repayments if your pre-tax salary is above the
            repayment threshold. Your repayment totals 9% of your earnings above
            the threshold on Plan 1, 2 or 4, or 6% of you earnings above the
            threshold if you are on a postgraduate plan.
          </p>
          <p>
            This calculator uses the inputs about your student loan debt and a
            number of assumptions to calculate your repayments each year. The
            calculator adjusts for future increases in your income, changes to
            the thresholds and the interest that accrues on your loan.
          </p>
        </>
      ) : (
        <AdSense.Google
          client="ca-pub-4419428690072900"
          slot="7207725730"
          style={{
            display: "block",
            textAlign: "center",
            minWidth: "200px",
          }}
          layout="in-article"
          format="fluid"
        />
      )}
    </div>
  );
}

export default ExplainerPanel;
