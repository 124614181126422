import historicalData from "../data/historicalData";
import numComma from "../elements/numComma";

// function validateInputs(dataToValidate) {
//   const minValues = inputData.min;
//   const maxValues = inputData.max;
//   const namesToValidate = [];

//   // Check the min values to see what needs to be validated

//   for (const property in minValues) {
//     if (minValues[property] !== null) {
//       namesToValidate.push(property);
//     }
//   }

//   // Now we have the names of the properties to validate, we now check the data

//   return validatedData;
// }

function calculator(studentData, manualData) {
  // The super deflator is the deflator for the current year
  let superDeflator = 0;
  // console.log("loanPlan", studentData.loanPlan);
  // console.log("baseRate", studentData.baseRate);

  // Create rpi time series data running from 1980 to 2129, used for initialising the deflators
  let rpiSeries = {};

  for (let i = 0; i < 150; i++) {
    let year = i + 1980;

    // Initialise the data structure for the year
    rpiSeries[year] = { rpi: undefined, rawRpiDeflator: undefined };

    // If using actual rpi values, set the rpi from the historical data
    rpiSeries[year].rpi =
      studentData.useActual === "checked" && historicalData.rpi[year]
        ? historicalData.rpi[year]
        : studentData.rpi;

    // Create the raw rpi deflator from the previous year's deflator and the rpi
    rpiSeries[year].rawRpiDeflator =
      i === 0
        ? 1
        : rpiSeries[year - 1].rawRpiDeflator / (1 + studentData.rpi / 100);

    // If this year is the current year, the super deflator is set as the raw rpi deflator for the year
    if (studentData.currentYear === year) {
      superDeflator = rpiSeries[year].rawRpiDeflator;
    }
  }

  // ------ //

  // Create annualised data

  // The number of years is as follows:
  // If the user is on plan 1, they start with 25 years
  // If the user is on plan 2, they start with 30 years
  // If the user is on plan 5, they start with 40 years
  let data = [];
  let repaymentPeriod = 30;
  switch (studentData.loanPlan) {
    case "plan1":
      repaymentPeriod = 25;
      break;
    case "plan1NI":
      repaymentPeriod = 25;
      break;
    case "plan5":
      repaymentPeriod = 40;
      break;
    default:
      break;
  }

  let arrayLength = repaymentPeriod + 1 + studentData.courseLength;
  if (studentData.setLoanSize === "checked") {
    arrayLength =
      repaymentPeriod +
      studentData.courseStartYear +
      studentData.courseLength +
      1 -
      studentData.currentYear;
  }

  // Then, if the user finished their course in the past, we initialise their years to be from when their course started.
  // I.e. if the user started their course in 2018, they complete it in 2021 which is courseLength + startYear
  // We initialise from the currentYear or the courseStartYear depending on whether or not setLoanSize is true
  // If setLoanSize is true, we start at the current year. The payment ends in X years.
  for (let i = 0; i < arrayLength; i++) {
    // Create an object for each year
    data.push({});

    // Create initial data structure. If manually setting loan size, we work from the currentYear, otherwise we work off the courseStartYear
    if (studentData.setLoanSize === "checked") {
      data[i].year = i + studentData.currentYear;
      data[i].yearsAfterUni =
        data[i].year - studentData.courseStartYear - studentData.courseLength;
    } else {
      data[i].year = i + studentData.courseStartYear;
      data[i].yearsAfterUni = i - studentData.courseLength;
    }

    // Once we know the year for the data, we can check if there is already historical data available for paymentFloor and rateCeiling

    data[i].currentPaymentFloor = studentData.currentPaymentFloor;
    data[i].currentRateCeiling = studentData.currentRateCeiling;

    if (studentData.useActual === "checked") {
      if (studentData.loanPlan === "plan2") {
        if (historicalData.currentPaymentFloor[data[i].year]) {
          data[i].currentPaymentFloor =
            historicalData.currentPaymentFloor[data[i].year];
        }
        if (historicalData.currentRateCeiling[data[i].year]) {
          data[i].currentRateCeiling =
            historicalData.currentRateCeiling[data[i].year];
        }
      } else if (studentData.loanPlan === "plan1") {
        if (historicalData.plan1Floor[data[i].year]) {
          data[i].currentPaymentFloor = historicalData.plan1Floor[data[i].year];
        }
      } else if (studentData.loanPlan === "plan1NI") {
        if (historicalData.plan1Floor[data[i].year]) {
          data[i].currentPaymentFloor = historicalData.plan1Floor[data[i].year];
        }
      } else if (studentData.loanPlan === "plan4") {
        if (historicalData.plan4Floor[data[i].year]) {
          data[i].currentPaymentFloor = historicalData.plan4Floor[data[i].year];
        }
      } else if (studentData.loanPlan === "plan5") {
        if (historicalData.plan5Floor[data[i].year]) {
          data[i].currentPaymentFloor = historicalData.plan5Floor[data[i].year];
        }
      }
    }

    // We take the rpi from the rpi series data
    data[i].rpi = rpiSeries[data[i].year].rpi;

    // We create the rpiDeflator by adjusting rawRpiDeflator with the superDeflator (which is based on the current year)
    data[i].rpiDeflator =
      Math.round(
        (rpiSeries[data[i].year].rawRpiDeflator / superDeflator) * 1000
      ) / 1000;

    data[i].ukEarningsInflator =
      Math.round(
        (1 + studentData.earningsGrowth / 100) **
          (data[i].year - studentData.currentYear) *
          1000
      ) / 1000;

    // You are in uni if you are in the courselength + 1 (because academic years straddle calendar years)
    data[i].inUni =
      studentData.courseStartYear + studentData.courseLength + 1 > data[i].year
        ? true
        : false;
    // }

    // We know we wipe the debt when yearsAfterUni is 31 or greater
    switch (studentData.loanPlan) {
      case "plan1":
        data[i].wiped = data[i].yearsAfterUni > 25 ? true : false;
        break;
      case "plan1NI":
        data[i].wiped = data[i].yearsAfterUni > 25 ? true : false;
        break;
      case "plan4":
        data[i].wiped = data[i].yearsAfterUni > 30 ? true : false;
        break;
      case "planPostgrad":
        data[i].wiped = data[i].yearsAfterUni > 30 ? true : false;
        break;
      case "plan5":
        data[i].wiped = data[i].yearsAfterUni > 40 ? true : false;
        break;
      default:
        data[i].wiped = data[i].yearsAfterUni > 30 ? true : false;
        break;
    }

    data[i].nominalSalary =
      // If setting loan manually, salary is the salary * the salaryGrowth
      studentData.setLoanSize === "checked"
        ? Math.round(
            studentData.salary * (1 + studentData.salaryGrowth / 100) ** i
          )
        : // If not setting loan manually, we check if you are in uni
        data[i].inUni
        ? // If in uni, no salary
          0
        : // If not in uni
        studentData.inflateSalary === "checked"
        ? // If we are inflating the salary, then inflate by salary growth (divided by the rpi deflator if in the future)
          Math.round(
            (studentData.salary *
              (1 + studentData.salaryGrowth / 100) **
                (data[i].yearsAfterUni - 1)) /
              Math.min(data[studentData.courseLength + 1].rpiDeflator, 1)
          )
        : // If not inflating salary, set salary (adjust by salary growth)
          Math.round(
            studentData.salary *
              (1 + studentData.salaryGrowth / 100) **
                (data[i].yearsAfterUni - 1)
          );

    // If the user has manually inputted salary for this year, set this instead.
    if (manualData.salary[i] !== undefined) {
      data[i].nominalSalary = manualData.salary[i];
    } // If the user has manually inputted salary for a previous year, base salary this year off the most recent salary.
    else if (manualData.salary !== {}) {
      let initialKeys = Object.keys(manualData.salary);
      // console.log(initialKeys);
      let filteredKeys = initialKeys.filter((key) => key < i);
      if (filteredKeys.length > 0) {
        data[i].nominalSalary = Math.round(
          data[i - 1].nominalSalary * (1 + studentData.salaryGrowth / 100)
        );
      }
    }

    data[i].realSalary = Math.round(
      data[i].nominalSalary * data[i].rpiDeflator
    );

    // If the currentPaymentFloor is not historical, adjust by the uk earnings inflator
    if (data[i].currentPaymentFloor === studentData.currentPaymentFloor) {
      data[i].paymentFloor = Math.round(
        data[i].currentPaymentFloor * data[i].ukEarningsInflator
      );
    } else {
      // Otherwise set payment floor to the default
      data[i].paymentFloor = data[i].currentPaymentFloor;
    }

    // Same as currentPaymentFloor
    if (data[i].currentRateCeiling === studentData.currentRateCeiling) {
      data[i].rateCeiling = Math.round(
        data[i].currentRateCeiling * data[i].ukEarningsInflator
      );
    } else {
      data[i].rateCeiling = data[i].currentRateCeiling;
    }

    data[i].rateFloorCeilingDiff = data[i].rateCeiling - data[i].paymentFloor;

    // Set interest rate depending on plan.
    if (studentData.loanPlan === "plan2") {
      // On plan 2, the loan is set depending on whether or not in uni.
      data[i].interestRate = data[i].inUni
        ? data[i].rpi + 3
        : Math.round(
            Math.max(
              Math.min(
                ((data[i].nominalSalary - data[i].paymentFloor) /
                  data[i].rateFloorCeilingDiff) *
                  3 +
                  data[i].rpi,
                data[i].rpi + 3
              ),
              data[i].rpi
            ) * 100
          ) / 100;
    } else if (
      studentData.loanPlan === "plan1" ||
      studentData.loanPlan === "plan4" ||
      studentData.loanPlan === "plan1NI"
    ) {
      // On plan 1 or 4, set to historical rate (if available), and otherwise to the minimum of rpi or base rate + 1
      if (
        studentData.useActual === "checked" &&
        historicalData.plan1Rate[data[i].year]
      ) {
        data[i].interestRate = historicalData.plan1Rate[data[i].year];
      } else {
        data[i].interestRate =
          Math.round(Math.min(data[i].rpi, studentData.baseRate + 1) * 100) /
          100;
      }
    } else if (studentData.loanPlan === "planPostgrad") {
      // On postgrad plan, set to rpi + 3%
      if (
        studentData.useActual === "checked" &&
        historicalData.rpi[data[i].year]
      ) {
        data[i].interestRate = historicalData.rpi[data[i].year] + 3;
      } else {
        data[i].interestRate = Math.round((data[i].rpi + 3) * 100) / 100;
      }
    } else if (studentData.loanPlan === "plan5") {
      // On plan 5, set to rpi
      if (
        studentData.useActual === "checked" &&
        historicalData.rpi[data[i].year]
      ) {
        data[i].interestRate = historicalData.rpi[data[i].year];
      } else {
        data[i].interestRate = Math.round(data[i].rpi * 100) / 100;
      }
    }

    // Check if the interest rate is set to a cap. If it is, we then check to see if the cap has been breached.
    if (historicalData.interestCap[studentData.loanPlan] !== null) {
      // Check if the cap occurs in the year mentioned
      if (historicalData.interestCap[studentData.loanPlan][data[i].year]) {
        // Check if the cap is less than the interest rate
        if (
          historicalData.interestCap[studentData.loanPlan][data[i].year] <=
          data[i].interestRate
        ) {
          // If so, set the interest rate to the cap
          data[i].interestRate =
            historicalData.interestCap[studentData.loanPlan][data[i].year];
        }
      }
    }

    if (manualData.interestRate[i] !== undefined) {
      // If the user has manually inputted the interest rate for this year, use this instead.
      data[i].interestRate = manualData.interestRate[i];
    } // If the user has manually inputted salary for a previous year, base salary this year off the most recent salary.
    else if (manualData.interestRate !== {}) {
      let initialKeys = Object.keys(manualData.interestRate);
      let filteredKeys = initialKeys.filter((key) => key < i);
      if (filteredKeys.length > 0) {
        data[i].interestRate = data[i - 1].interestRate;
      }
    }

    // If the user is inputting their student loan total, then we ignore fee and loan.
    if (studentData.setLoanSize === "checked") {
      data[i].feeAndLoan = 0;
    } else {
      // If the user is setting individual years by loan, we use those individual figures
      if (studentData.loanByYear === "checked") {
        data[i].feeAndLoan =
          data[i].yearsAfterUni < 0
            ? studentData[`loanByYearTuition${i}`] +
              studentData[`loanByYearMaintenance${i}`]
            : 0;
      } else {
        data[i].feeAndLoan = data[i].inUni
          ? // If in the first year of uni, set the student loan payments to 1/3 of yearly total
            i === 0
            ? (studentData.tuitionFee + studentData.maintenance) / 3
            : // If in the last year of uni, set the student loan payments to 2/3 of yearly total
            data[i].yearsAfterUni === 0
            ? ((studentData.tuitionFee + studentData.maintenance) * 2) / 3
            : studentData.tuitionFee + studentData.maintenance
          : 0;
      }
    }

    // If i = 0 then debt accrued is equal to the inputted value
    if (studentData.setLoanSize === "checked" && i === 0) {
      data[i].debtAccrued = studentData.currentDebt;
    } else {
      data[i].debtAccrued =
        Math.round(
          (data[i].feeAndLoan +
            (i > 0 ? data[i - 1].accruedMinusRepayment : 0)) *
            100
        ) / 100;
    }

    data[i].interestAdded =
      Math.round(data[i].debtAccrued * (data[i].interestRate / 100) * 100) /
      100;

    data[i].accruedPlusInterest =
      Math.round((data[i].debtAccrued + data[i].interestAdded) * 100) / 100;

    // Overpayments
    data[i].overpaymentAnnualReal =
      data[i].inUni || data[i].wiped ? 0 : studentData.overpayment * 12;

    data[i].overpaymentAnnualNominal =
      data[i].overpaymentAnnualReal / data[i].rpiDeflator;

    // If the user has manually inputted overpayments for this year, set this instead.
    if (manualData.overpayment[i] !== undefined) {
      data[i].overpaymentAnnualNominal = manualData.overpayment[i];
      data[i].overpaymentAnnualReal =
        data[i].overpaymentAnnualNominal * data[i].rpiDeflator;
    } // If the user has manually inputted overpayments for a previous year, base overpayments this year off the most recent overpayment.
    else if (manualData.overpayment !== {}) {
      let initialKeys = Object.keys(manualData.overpayment);
      // console.log(initialKeys);
      let filteredKeys = initialKeys.filter((key) => key < i);
      if (filteredKeys.length > 0) {
        data[i].overpaymentAnnualNominal = Math.round(
          data[i - 1].overpaymentAnnualNominal * (1 + data[i].rpi / 100)
        );
        data[i].overpaymentAnnualReal =
          data[i].overpaymentAnnualNominal * data[i].rpiDeflator;
      }
    }

    // The adjusted overpayment is never larger than remaining debt
    data[i].overpaymentAnnualNominalAdjusted =
      Math.round(
        Math.min(
          data[i].overpaymentAnnualNominal,
          data[i].accruedPlusInterest
        ) * 100
      ) / 100;

    data[i].overpaymentAnnualRealAdjusted =
      Math.round(
        data[i].overpaymentAnnualNominalAdjusted * data[i].rpiDeflator * 100
      ) / 100;

    // Do we make repayments?
    data[i].repayments =
      data[i].wiped ||
      data[i].debtAccrued === 0 ||
      data[i].inUni ||
      (studentData.loanPlan === "plan2" && data[i].year < 2016)
        ? false
        : data[i].nominalSalary > data[i].paymentFloor ||
          studentData.overpaymentAnnualReal
        ? true
        : false;

    // Repayments: pure denotes repayment not including overpayments
    // For postgraduate, you pay at 6% above the threshold:
    if (studentData.loanPlan === "planPostgrad") {
      data[i].repaymentAmountNominalPure = data[i].repayments
        ? Math.round(
            Math.min(
              Math.max(
                (data[i].nominalSalary - data[i].paymentFloor) * 0.06,
                0
              ),
              data[i].accruedPlusInterest -
                data[i].overpaymentAnnualNominalAdjusted
            ) * 100
          ) / 100
        : 0;
    } else {
      data[i].repaymentAmountNominalPure = data[i].repayments
        ? Math.round(
            Math.min(
              Math.max(
                (data[i].nominalSalary - data[i].paymentFloor) * 0.09,
                0
              ),
              data[i].accruedPlusInterest -
                data[i].overpaymentAnnualNominalAdjusted
            ) * 100
          ) / 100
        : 0;
    }

    data[i].repaymentAmountRealPure =
      Math.round(
        data[i].repaymentAmountNominalPure * data[i].rpiDeflator * 100
      ) / 100;

    // Non pure denotes total
    data[i].repaymentAmountNominal =
      data[i].repaymentAmountNominalPure +
      data[i].overpaymentAnnualNominalAdjusted;

    data[i].repaymentAmountReal =
      data[i].repaymentAmountRealPure + data[i].overpaymentAnnualRealAdjusted;

    data[i].repaymentTotalNominal =
      Math.round(
        (data[i].repaymentAmountNominal +
          (i > 0 ? data[i - 1].repaymentTotalNominal : 0)) *
          100
      ) / 100;

    data[i].repaymentTotalReal =
      Math.round(
        (data[i].repaymentAmountReal +
          (i > 0 ? data[i - 1].repaymentTotalReal : 0)) *
          100
      ) / 100;

    data[i].overpaymentTotalNominal =
      Math.round(
        (data[i].overpaymentAnnualNominalAdjusted +
          (i > 0 ? data[i - 1].overpaymentTotalNominal : 0)) *
          100
      ) / 100;

    data[i].overpaymentTotalReal =
      Math.round(
        (data[i].overpaymentAnnualRealAdjusted +
          (i > 0 ? data[i - 1].overpaymentTotalReal : 0)) *
          100
      ) / 100;

    data[i].accruedMinusRepayment =
      Math.round(
        (data[i].accruedPlusInterest - data[i].repaymentAmountNominal) * 100
      ) / 100;

    data[i].accruedMinusRepaymentReal =
      Math.round(data[i].accruedMinusRepayment * data[i].rpiDeflator * 100) /
      100;
  }

  // ------------------- //

  // Create summary data

  // For table

  let tableData = [];
  for (let i = 0; i < data.length; i++) {
    tableData.push([
      i,
      data[i].year,
      data[i].nominalSalary,
      Math.round(data[i].overpaymentAnnualNominalAdjusted),
      data[i].interestRate,
      Math.round(data[i].debtAccrued),
      Math.round(data[i].interestAdded),
      Math.round(data[i].repaymentAmountNominal),
      Math.round(data[i].repaymentTotalNominal),
    ]);
  }

  // For repayments module in results
  let startingPaymentIndex = -1;
  for (let i = 0; i < data.length; i++) {
    if (startingPaymentIndex === -1 && data[i].yearsAfterUni > 0) {
      startingPaymentIndex = i;
    }
  }

  // Date cleared:
  let dateCleared = false;
  data.forEach((item) => {
    if (
      item.yearsAfterUni > 0 &&
      item.accruedMinusRepayment <= 0 &&
      dateCleared === false
    ) {
      dateCleared = item.yearsAfterUni;
    }
  });

  // Repayment Schedule:
  let repaymentScheduleNominal = [];
  data.forEach((item) => {
    repaymentScheduleNominal.push(item.repaymentAmountNominal);
  });

  let repaymentScheduleReal = [];
  data.forEach((item) => {
    repaymentScheduleReal.push(item.repaymentAmountReal);
  });

  // Graph Data:
  let repaymentGraph = [];
  data.forEach((item) => {
    repaymentGraph.push({
      amount: item.repaymentAmountRealPure,
      year: new Date(item.year, 1, 1),
    });
  });

  let repaymentGraphNominal = [];
  data.forEach((item) => {
    repaymentGraphNominal.push({
      amount: item.repaymentAmountNominalPure,
      year: new Date(item.year, 1, 1),
    });
  });

  let repaymentGraphOverpayment = [];
  data.forEach((item) => {
    repaymentGraphOverpayment.push({
      amount: item.overpaymentAnnualRealAdjusted,
      year: new Date(item.year, 1, 1),
    });
  });

  let repaymentGraphOverpaymentNominal = [];
  data.forEach((item) => {
    repaymentGraphOverpaymentNominal.push({
      amount: item.overpaymentAnnualNominalAdjusted,
      year: new Date(item.year, 1, 1),
    });
  });

  let salaryGraph = [];
  data.forEach((item) => {
    salaryGraph.push({
      amount: item.realSalary,
      year: new Date(item.year, 1, 1),
    });
  });

  let salaryGraphNominal = [];
  data.forEach((item) => {
    salaryGraphNominal.push({
      amount: item.nominalSalary,
      year: new Date(item.year, 1, 1),
    });
  });

  let salaryRepaymentGraph = [];
  data.forEach((item) => {
    salaryRepaymentGraph.push({
      amount:
        Math.round(
          (item.repaymentAmountNominal / item.nominalSalary) * 1000
            ? (item.repaymentAmountNominal / item.nominalSalary) * 1000
            : 0
        ) /
          10 >
        100
          ? 0
          : Math.round(
              (item.repaymentAmountNominal / item.nominalSalary) * 1000
                ? (item.repaymentAmountNominal / item.nominalSalary) * 1000
                : 0
            ) / 10,

      year: new Date(item.year, 1, 1),
    });
  });

  let totalRepaymentsGraph = [];
  data.forEach((item) => {
    totalRepaymentsGraph.push({
      amount: Math.round(item.repaymentTotalReal ? item.repaymentTotalReal : 0),
      year: new Date(item.year, 1, 1),
      prepend: "you will have made ",
      append: " in total repayments",
    });
  });

  let totalRepaymentsGraphNominal = [];
  data.forEach((item) => {
    totalRepaymentsGraphNominal.push({
      amount: Math.round(
        item.repaymentTotalNominal ? item.repaymentTotalNominal : 0
      ),
      year: new Date(item.year, 1, 1),
      prepend: "you will have made ",
      append: " in total repayments",
    });
  });

  let totalDebt = [];
  data.forEach((item) => {
    totalDebt.push({
      amount: Math.round(item.accruedMinusRepaymentReal),
      year: new Date(item.year, 1, 1),
      prepend: "you will have ",
      append: " in debt",
    });
  });

  let totalDebtNominal = [];
  data.forEach((item) => {
    totalDebtNominal.push({
      amount: Math.round(item.accruedMinusRepayment),
      year: new Date(item.year, 1, 1),
      prepend: "you will have ",
      append: " in debt",
    });
  });

  let showPie =
    Math.round(data[data.length - 1].repaymentTotalReal) === 0 ||
    Math.round(data[data.length - 1].accruedMinusRepaymentReal) === 0
      ? false
      : true;

  let repaymentPie = [];
  repaymentPie.push({
    amount: Math.round(data[data.length - 1].repaymentTotalReal),
    year: new Date(data[data.length - 1].year, 1, 1),
    label: `Total Repayments: £${numComma(
      Math.round(data[data.length - 1].repaymentTotalReal)
    )}`,
  });
  repaymentPie.push({
    amount: Math.round(data[data.length - 1].accruedMinusRepaymentReal),
    year: new Date(data[data.length - 1].year, 1, 1),
    label: `Final Debt Written Off: £${numComma(
      Math.round(data[data.length - 1].accruedMinusRepaymentReal)
    )}`,
  });

  let repaymentPieNominal = [];
  repaymentPieNominal.push({
    amount: Math.round(data[data.length - 1].repaymentTotalNominal),
    year: new Date(data[data.length - 1].year, 1, 1),
    label: `Total Repayments: £${numComma(
      Math.round(data[data.length - 1].repaymentTotalNominal)
    )}`,
  });
  repaymentPieNominal.push({
    amount: Math.round(data[data.length - 1].accruedMinusRepayment),
    year: new Date(data[data.length - 1].year, 1, 1),
    label: `Final Debt Written Off: £${numComma(
      Math.round(data[data.length - 1].accruedMinusRepayment)
    )}`,
  });

  let summaryData = {
    // For table
    tableData: tableData,

    // For repayments module in results
    startingPaymentIndex: startingPaymentIndex,

    // Date cleared
    dateCleared: dateCleared,

    // For IRR calculation
    repaymentScheduleNominal: repaymentScheduleNominal,
    repaymentScheduleReal: repaymentScheduleReal,

    // For graphs
    repaymentGraph: repaymentGraph,
    repaymentGraphNominal: repaymentGraphNominal,
    repaymentGraphOverpayment: repaymentGraphOverpayment,
    repaymentGraphOverpaymentNominal: repaymentGraphOverpaymentNominal,
    salaryGraph: salaryGraph,
    salaryRepaymentGraph: salaryRepaymentGraph,
    totalRepaymentsGraph: totalRepaymentsGraph,
    salaryGraphNominal: salaryGraphNominal,
    totalRepaymentsGraphNominal: totalRepaymentsGraphNominal,
    totalDebt: totalDebt,
    totalDebtNominal: totalDebtNominal,
    repaymentPie: repaymentPie,
    repaymentPieNominal: repaymentPieNominal,
    showPie: showPie,
  };

  // console.log(data);
  // console.log(summaryData);
  return { data: data, summaryData: summaryData };
}

export default calculator;
