function numComma(number) {
  const roundedNumber = Math.round(number);
  let string = roundedNumber.toString();
  let processedString = "";
  let comma = ",";
  while (string.length > 3) {
    processedString = comma.concat(string.slice(-3), processedString);
    string = string.slice(0, -3);
  }
  while (string.length > 0) {
    processedString = string.concat(processedString);
    return processedString;
  }
  return processedString;
}

export default numComma;
