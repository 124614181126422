import { InputGroup, Form } from "react-bootstrap";
import Popover from "./popover.js";
import inputData from "../data/inputData";

const InputBox = (props) => {
  let value = props.value;
  return (
    <>
      <Form.Label>
        {value.label}
        {inputData.popoverTitle[value.name] === "" ? null : (
          <Popover
            popoverTitle={inputData.popoverTitle[value.name]}
            popoverContent={inputData.popoverContent[value.name]}
          />
        )}
      </Form.Label>
      <InputGroup className="mb-3">
        <Form.Check
          disabled={value.disabled}
          type={value.type}
          name={value.name}
          value={value.dataName}
          checked={value.dataName === "checked" ? true : false}
          onChange={(e) => {
            if (value.name === "loanByYear" && value.dataName === "unchecked") {
              props.setShowModal(true);
            }
            value.handleInput(e, value.data);
          }}
          onKeyUp={(e) => {
            value.handleKeyUp(e, value.data);
          }}
        />
      </InputGroup>
      {value.disabled && value.disabledText !== "" ? (
        <>
          {" "}
          <Form.Text className="text-muted">
            <i className="bi bi-exclamation-circle-fill"></i>{" "}
            {value.disabledText}
          </Form.Text>{" "}
          <br />
        </>
      ) : null}
    </>
  );
};

export default InputBox;
