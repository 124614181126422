import React from "react";
import { Table, FormControl, InputGroup, Button } from "react-bootstrap";
import Popover from "./popover";
import resultsPopoverData from "../data/resultsPopoverData";

const TableRow = (props) => {
  let rowContents = [];
  for (let i = 0; i < props.rowData.length; i++) {
    if (i === 1 || i === 0) {
      rowContents.push(<td key={i}>{props.rowData[i]}</td>);
    } else if (i === 2) {
      rowContents.push(
        <td key={i}>
          <InputGroup size="sm">
            <InputGroup.Prepend>
              <InputGroup.Text id="basic-addon1">£</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              value={props.rowData[i]}
              aria-label="Salary"
              aria-describedby="basic-addon1"
              name="salary"
              entrymethod="manual"
              row={props.rowNo}
              onChange={(e) => {
                props.handleManualInput(
                  e,
                  props.appData.manualData,
                  props.appData.setManualData
                );
              }}></FormControl>
            {props.appData.manualData.salary[props.rowNo] !== undefined ? (
              <InputGroup.Append>
                <InputGroup.Text id="basic-addon1">
                  <i
                    name="salary"
                    entrymethod="clear"
                    row={props.rowNo}
                    onClick={(e) => {
                      props.handleManualInput(
                        e,
                        props.appData.manualData,
                        props.appData.setManualData
                      );
                    }}
                    class="bi bi-trash"
                    style={{ cursor: "pointer" }}></i>
                </InputGroup.Text>
              </InputGroup.Append>
            ) : null}
          </InputGroup>
        </td>
      );
    } else if (i === 3) {
      rowContents.push(
        <td key={i}>
          <InputGroup size="sm">
            <InputGroup.Prepend>
              <InputGroup.Text id="basic-addon1">£</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              value={props.rowData[i]}
              aria-label="Overpayment"
              aria-describedby="basic-addon1"
              name="overpayment"
              entrymethod="manual"
              row={props.rowNo}
              onChange={(e) => {
                props.handleManualInput(
                  e,
                  props.appData.manualData,
                  props.appData.setManualData
                );
              }}></FormControl>
            {props.appData.manualData.overpayment[props.rowNo] !== undefined ? (
              <InputGroup.Append>
                <InputGroup.Text id="basic-addon1">
                  <i
                    name="overpayment"
                    entrymethod="clear"
                    row={props.rowNo}
                    onClick={(e) => {
                      props.handleManualInput(
                        e,
                        props.appData.manualData,
                        props.appData.setManualData
                      );
                    }}
                    class="bi bi-trash"
                    style={{ cursor: "pointer" }}></i>
                </InputGroup.Text>
              </InputGroup.Append>
            ) : null}
          </InputGroup>
        </td>
      );
    } else if (i === 4) {
      rowContents.push(
        <td key={i}>
          <InputGroup size="sm">
            <FormControl
              value={props.rowData[i]}
              aria-label="Interest Rate"
              aria-describedby="basic-addon1"
              name="interestRate"
              entrymethod="manual"
              row={props.rowNo}
              onChange={(e) => {
                props.handleManualInput(
                  e,
                  props.appData.manualData,
                  props.appData.setManualData
                );
              }}></FormControl>
            <InputGroup.Append>
              <InputGroup.Text id="basic-addon1">%</InputGroup.Text>
            </InputGroup.Append>
            {props.appData.manualData.interestRate[props.rowNo] !==
            undefined ? (
              <InputGroup.Append>
                <InputGroup.Text id="basic-addon1">
                  <i
                    name="interestRate"
                    entrymethod="clear"
                    row={props.rowNo}
                    onClick={(e) => {
                      props.handleManualInput(
                        e,
                        props.appData.manualData,
                        props.appData.setManualData
                      );
                    }}
                    class="bi bi-trash"
                    style={{ cursor: "pointer" }}></i>
                </InputGroup.Text>
              </InputGroup.Append>
            ) : null}
          </InputGroup>
        </td>
      );
    } else {
      rowContents.push(<td key={i}>£{props.rowData[i]}</td>);
    }
  }
  return <tr>{rowContents}</tr>;
};

const TableDisplay = (props) => {
  let tableData = props.appData.studentData.summaryData.tableData;
  let tableContents = [];
  for (let i = 0; i < tableData.length; i++) {
    if (tableData[i][5] > 0 || tableData[i - 1][5] > 0) {
      tableContents.push(
        <TableRow
          key={i}
          rowNo={i}
          rowData={tableData[i]}
          appData={props.appData}
          handleManualInput={props.handleManualInput}
        />
      );
    }
  }

  return (
    <div className="resultsBox">
      <div className="resultsBoxContent">
        <Popover
          popoverTitle="Data Table"
          popoverContent={resultsPopoverData.dataTable}
        />
      </div>
      <div className="toggleFlex">
        <div
          style={{
            marginRight: "auto",
          }}>
          <h5>Data Table</h5>
        </div>
        <Button
          onClick={() => {
            props.appData.setManualData({
              salary: {},
              overpayment: {},
              interestRate: {},
            });
          }}
          className="resultsButton"
          variant={`outline-primary`}>
          Clear Manual Inputs
        </Button>
      </div>
      <br />
      <div className="tableScroll">
        <Table striped bordered hover size="sm" className="table">
          <thead>
            <tr>
              <th>#</th>
              <th>Year</th>
              <th className="salaryTableInput">Salary</th>
              <th>
                Annual Overpayment{" "}
                <Popover
                  popoverTitle="Annual Overpayment"
                  popoverContent={resultsPopoverData.annualOverpayment}
                />
              </th>
              <th>Interest Rate</th>
              <th>Total Debt</th>
              <th>Annual Interest</th>
              <th>Annual Repayment</th>
              <th>Total Repaid</th>
            </tr>
          </thead>
          <tbody>{tableContents}</tbody>
        </Table>
      </div>
    </div>
  );
};

export default TableDisplay;
