import { useState } from "react";
import {
  VictoryBar,
  VictoryChart,
  VictoryAxis,
  VictoryTooltip,
  VictoryTheme,
} from "victory";
import numComma from "../elements/numComma";
import ToggleGroup from "../elements/toggleGroup";
import graphFill from "../elements/graphFill";
import Popover from "../elements/popover";

const SalaryGraph = (props) => {
  const [real, setReal] = useState("true");
  if (
    Math.round(
      props.studentData.data[props.studentData.data.length - 1].nominalSalary
    ) === 0
  ) {
    return null;
  }

  return (
    <div className="resultsGraphBox">
      <div className="resultsBoxContent">
        <Popover
          popoverTitle="Real vs Nominal"
          popoverContent="Nominal refers to the stated value at a point in time. Real refers to the amount adjusted for inflation. By using real values, you can easily compare values in the future to values today."
        />
      </div>
      <div className="toggleFlex">
        <h5>Salary {/* <DetailsExpandable p="+" variant="primary" /> */}</h5>
        <ToggleGroup
          variant={props.variant1}
          handleInput={() => {
            setReal(!real);
          }}
          real={real}
        />
      </div>
      <div className="resultsGraphFlex">
        <div className="resultsGraph">
          <VictoryChart
            theme={VictoryTheme.material}
            domainPadding={{ x: [14, 14], y: 14 }}
            width={750}
            scale={{ x: "time", y: "linear" }}
            padding={{ top: 30, left: 100, right: 50, bottom: 50 }}>
            <VictoryAxis
              label="Year"
              style={{
                axisLabel: {
                  padding: 36,
                  fontSize: 15,
                },
              }}
            />
            <VictoryAxis
              dependentAxis
              label={real ? "Real Salary £" : "Nominal Salary £"}
              style={{
                axisLabel: {
                  padding: 60,
                  fontSize: 15,
                },
                grid: {
                  stroke: "darkGrey",
                },
              }}
              // label="Repayment £"
            />
            <VictoryBar
              alignment="start"
              style={{
                data: {
                  fill: graphFill[props.variant1],
                  width: "14",
                },
                labels: { fill: "black", fontSize: "14px" },
              }}
              name="test"
              data={
                real
                  ? props.studentData.summaryData.salaryGraph
                  : props.studentData.summaryData.salaryGraphNominal
              }
              labels={({ datum }) =>
                Math.round(datum.amount) > 0
                  ? `In ${datum.year.getFullYear()} your salary will be £${numComma(
                      Math.round(datum.amount)
                    )}`
                  : null
              }
              labelComponent={
                <VictoryTooltip
                  dy={-14}
                  dx={7}
                  cornerRadius={3}
                  constrainToVisibleArea
                  style={{ fill: "rgb(250,250,250)" }}
                  flyoutPadding={6}
                  flyoutStyle={{
                    fill: "rgba(0, 0, 0, 0.8)",
                    strokeWidth: 0,
                    boxShadow: "10px 10px",
                  }}
                />
              }
              // data accessor for x values
              x="year"
              // data accessor for y values
              y="amount"
            />
          </VictoryChart>
          <br />
        </div>

        {props.inUse.inUse[1] ? (
          <div className="resultsGraph">
            <VictoryChart
              theme={VictoryTheme.material}
              domainPadding={{ x: [14, 14], y: 14 }}
              width={750}
              scale={{ x: "time", y: "linear" }}
              padding={{ top: 50, left: 100, right: 50, bottom: 50 }}>
              <VictoryAxis
                label="Year"
                style={{
                  axisLabel: {
                    padding: 36,
                    fontSize: 15,
                  },
                }}
              />
              <VictoryAxis
                dependentAxis
                label={
                  real
                    ? "Comparison Real Salary £"
                    : "Comparison Nominal Salary £"
                }
                style={{
                  axisLabel: {
                    padding: 60,
                    fontSize: 15,
                  },
                  grid: {
                    stroke: "darkGrey",
                  },
                }}
              />
              <VictoryBar
                alignment="start"
                style={{
                  data: {
                    fill: graphFill[props.variant2],
                    width: "14",
                  },
                  labels: { fill: "black", fontSize: "14px" },
                }}
                name="test"
                data={
                  real
                    ? props.comparisonData.summaryData.salaryGraph
                    : props.comparisonData.summaryData.salaryGraphNominal
                }
                labels={({ datum }) =>
                  Math.round(datum.amount) > 0
                    ? `In ${datum.year.getFullYear()} your salary will be £${numComma(
                        Math.round(datum.amount)
                      )}`
                    : null
                }
                labelComponent={
                  <VictoryTooltip
                    dy={-14}
                    dx={7}
                    cornerRadius={3}
                    constrainToVisibleArea
                    style={{ fill: "rgb(250,250,250)" }}
                    flyoutPadding={6}
                    flyoutStyle={{
                      fill: "rgba(0, 0, 0, 0.8)",
                      strokeWidth: 0,
                      boxShadow: "10px 10px",
                    }}
                  />
                }
                // data accessor for x values
                x="year"
                // data accessor for y values
                y="amount"
              />
            </VictoryChart>
            <br />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default SalaryGraph;
