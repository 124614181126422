const mortgageInputData = {
  popoverTitle: {
    rate: "Mortgage Interest Rate",
    term: "Mortgage Term Length (In years)",
    principal: "Mortgage Amount",
    mortgageType: "Mortgage Type",
  },
  popoverContent: {
    rate: (
      <ul>
        <li>Set the mortgage interest rate.</li>
      </ul>
    ),
    term: "This is the average cost of your Tuition Fee per year at University. Make sure account for any bursary payments that may reduce this value.",
    principal:
      "In calculating your repayments and interest rate, we need to make assumptions about your salary growth in the future.",
    mortgageType:
      "This is the type of mortgage you are taking out. Repayment mortgages are where you pay off the interest and the capital each month. Interest only mortgages are where you only pay off the interest each month and the capital is paid off at the end of the mortgage term.",
  },
  min: {
    rate: 0,
    term: 0,
    principal: 0,
    mortgageType: null,
  },
  max: {
    rate: 50,
    term: 100,
    principal: 10000000,
    mortgageType: null,
  },
  default: {
    //Input variables
    rate: 4.5,
    term: 25,
    principal: 150000,
    mortgageType: "repayment",
    // Non-input Variables
    currentYear: new Date().getFullYear(),
    data: [],
    summaryData: {},
  },
  inUseDefault: {
    inUse: [true, false],
    variants: ["primary", "warning"],
    cache: [],
    comparing: false,
    recalculate: [false, false],
    submit: false,
  },
};

export default mortgageInputData;
