import React from "react";

/**
 * Renders the disclaimer panel.
 * 
 * @returns {JSX.Element} The JSX element representing the disclaimer panel.
 */
const disclaimerPanel = () => {
  return (
    <div className="panel disclaimerPanel">
      <p style={{ fontSize: "0.9rem", margin: "0" }}>
        Disclaimer: This tool does not consitute financial advice. We do not
        recommend taking actions based on the results of this tool; always do
        your own research first, and check a trusted source such as the UK
        Government website. Note that the tool makes many assumptions and so the
        results could be inaccurate; for example, Government policy could change
        and so the assumptions in the tool may become outdated. We can't
        guarantee to be perfect, so do note you use the information at your own
        risk and we can't accept liability if things go wrong. Visit{" "}
        <a
          target="_blank"
          rel="noreferrer"
          style={{ color: "DarkBlue" }}
          href="https://www.gov.uk/repaying-your-student-loan">
          https://www.gov.uk/repaying-your-student-loan
        </a>{" "}
        to find out more information about UK student loans.
      </p>
    </div>
  );
};

export default disclaimerPanel;
