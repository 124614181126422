import React from "react";

const TextPanel = () => {
  return (
    <div className="explainerPanel">
      <div>
        <h5>
          <i className="bi bi-house"></i> How much could my mortgage repayments
          be?
        </h5>
        <p>
          This calculator helps to provide an overview of what your repayments
          could be based on the interest rate, term and repayment type selected.
        </p>
      </div>
    </div>
  );
};

export default TextPanel;
