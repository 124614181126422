import React from "react";
import { Popover, OverlayTrigger } from "react-bootstrap";

const PopoverComponent = (props) => (
  <OverlayTrigger
    trigger={["hover", "focus"]}
    placement={"auto"}
    overlay={
      <Popover id="popover-basic">
        <Popover.Title as="h3">{props.popoverTitle}</Popover.Title>
        <Popover.Content>{props.popoverContent}</Popover.Content>
      </Popover>
    }>
    <i className="bi bi-question-circle"></i>
  </OverlayTrigger>
);

export default PopoverComponent;
