import React from "react";
import { Navbar, Nav, Button } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import Scroll from "react-scroll";
const ScrollLink = Scroll.Link;

/**
 * HeaderPanel component represents the header section of the application.
 * It displays a navigation bar with various links and a logo.
 *
 * @returns {JSX.Element} The rendered HeaderPanel component.
 */
const HeaderPanel = () => {
  return (
    <div className="headerPanel panel" style={{ position: "static" }}>
      <Navbar
        bg="primary"
        variant="dark"
        expand="lg"
        style={{
          background:
            "linear-gradient(160deg, rgba(36,84,50,1) 0%, rgba(43,94,58,1) 50%,  rgba(53,108,70,1) 100%)",
        }}>
        <ScrollLink
          className="topScrollToEvent compareButton"
          to="topScrollToEvent"
          spy={true}
          duration={0}>
          <NavLink to="/">
            <Navbar.Brand>
              <img
                alt=""
                src="/whiteLogo64.png"
                width="30px"
                height="30px"
                className="d-inline-block align-top"
              />
              {"  "}UK Student Loan Calculator
            </Navbar.Brand>
          </NavLink>
        </ScrollLink>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            <ScrollLink
              className="topScrollToEvent compareButton"
              to="topScrollToEvent"
              spy={true}
              duration={0}>
              <Nav.Link as={NavLink} to="/" active="false">
                Student Loan Calculator <i className="bi bi-calculator"></i>
              </Nav.Link>
            </ScrollLink>

            {/* <ScrollLink
              className="topScrollToEvent compareButton"
              to="topScrollToEvent"
              spy={true}
              duration={0}>
              <Nav.Link as={NavLink} to="/mortgage" active="false">
                Mortage Calculator <i className="bi bi-house"></i>
              </Nav.Link>
            </ScrollLink> */}

            <ScrollLink
              className="topScrollToEvent compareButton"
              to="topScrollToEvent"
              spy={true}
              duration={0}>
              <Nav.Link as={NavLink} to="/faqs" active="false">
                FAQs <i className="bi bi-question-square"></i>
              </Nav.Link>
            </ScrollLink>

            <ScrollLink
              className="topScrollToEvent compareButton"
              to="topScrollToEvent"
              spy={true}
              duration={0}>
              <Nav.Link as={NavLink} to="/about" active="false">
                About <i className="bi bi-card-text"></i>
              </Nav.Link>
            </ScrollLink>

            <div style={{ marginLeft: "10px" }}></div>
            <Button
              style={{ borderWidth: "0px" }}
              className="compareButton"
              href="https://mailchi.mp/ef3e8218cfc6/student-calc-newsletter"
              target="_blank"
              active="false"
              variant="primary">
              Get the newsletter <i class="bi bi-envelope"></i>
            </Button>
            <div style={{ marginLeft: "10px" }}></div>

            <Button
              style={{ borderWidth: "0px" }}
              className="compareButton"
              href="https://buy.stripe.com/bIY5lbafm9SmeIM8wx"
              target="_blank"
              active="false"
              variant="primary">
              Buy your Report <i class="bi bi-file-bar-graph-fill"></i>
            </Button>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default HeaderPanel;
