import { useState } from "react";
import { VictoryPie, VictoryLabel, Rect, VictoryTheme } from "victory";
import ToggleGroup from "../elements/toggleGroup";
import graphFill from "../elements/graphFill";
import Popover from "../elements/popover";

const RepaymentPieGraph = (props) => {
  const [real, setReal] = useState("true");

  if (
    Math.round(
      props.studentData.data[props.studentData.data.length - 1].nominalSalary
    ) === 0
  ) {
    return null;
  }

  return props.studentData.summaryData.showPie ? (
    <div className="resultsGraphBox">
      <div className="resultsBoxContent">
        <Popover
          popoverTitle="Real vs Nominal"
          popoverContent="Nominal refers to the stated value at a point in time. Real refers to the amount adjusted for inflation. By using real values, you can easily compare values in the future to values today."
        />
      </div>
      <div className="toggleFlex">
        <h5>Repayments vs Debt Written Off</h5>
        <ToggleGroup
          variant={props.variant1}
          handleInput={() => {
            setReal(!real);
          }}
          real={real}
        />
      </div>
      <div className="resultsGraphFlex">
        <div className="resultsGraph">
          <VictoryPie
            theme={VictoryTheme.material}
            width={700}
            labelComponent={
              <VictoryLabel
                {...props}
                backgroundStyle={[{ fill: "rgba(0, 0, 0, 0.8)" }]}
                backgroundPadding={6}
                backgroundComponent={<Rect rx="3px" />}
              />
            }
            // labelRadius={100}
            cornerRadius={2}
            padAngle={1}
            innerRadius={60}
            colorScale={[graphFill["primary"], graphFill["info"]]}
            style={{
              labels: {
                fill: "white",
                fontSize: "13px",
                backgroundFill: "black",
              },
              // data: {
              //   stroke: "black",
              //   strokeWidth: 1,
              // },
            }}
            name="test"
            data={
              real
                ? props.studentData.summaryData.repaymentPie
                : props.studentData.summaryData.repaymentPieNominal
            }
            // data accessor for x values
            x="year"
            // data accessor for y values
            y="amount"
          />
          <br />
        </div>

        {props.inUse.inUse[1] ? (
          <div className="resultsGraph">
            <VictoryPie
              theme={VictoryTheme.material}
              width={700}
              labelComponent={
                <VictoryLabel
                  {...props}
                  backgroundStyle={[{ fill: "rgba(0, 0, 0, 0.8)" }]}
                  backgroundPadding={6}
                  backgroundComponent={<Rect rx="3px" />}
                />
              }
              cornerRadius={2}
              padAngle={1}
              innerRadius={60}
              colorScale={[graphFill[props.variant2], graphFill["info"]]}
              style={{
                labels: {
                  fill: "white",
                  fontSize: "14px",
                  backgroundFill: "black",
                },
                // data: {
                //   stroke: "black",
                //   strokeWidth: 1,
                // },
              }}
              name="test"
              data={
                real
                  ? props.comparisonData.summaryData.repaymentPie
                  : props.comparisonData.summaryData.repaymentPieNominal
              }
              // data accessor for x values
              x="year"
              // data accessor for y values
              y="amount"
            />
            <br />
          </div>
        ) : null}
      </div>
    </div>
  ) : null;
};

export default RepaymentPieGraph;
