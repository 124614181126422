import React, { useState } from "react";
import { Badge, Button, ButtonGroup } from "react-bootstrap";
import RepaymentGraph from "../graphs/repaymentGraph";
import SalaryGraph from "../graphs/salaryGraph";
import SalaryRepaymentGraph from "../graphs/salaryRepaymentGraph";
import TotalRepaymentsGraph from "../graphs/totalRepaymentsGraph";
import RepaymentPieGraph from "../graphs/repaymentPieGraph";
import TableDisplay from "../elements/tableDisplay";
import HintDismissable from "../elements/hint";
import Scroll from "react-scroll";
import numComma from "../elements/numComma";
import ModalClass from "../elements/modal";
import Popover from "../elements/popover";
import AdSense from "react-adsense";
import resultsPopoverData from "../data/resultsPopoverData";

const Element = Scroll.Element;

const NavLink = Scroll.Link;

const handleManualInput = (e, manualData, setManualData) => {
  console.log(
    "entry by user.",
    e.target,
    e.target.getAttribute("row"),
    e.target.getAttribute("name")
  );
  let output = {};

  Object.assign(output, manualData);

  //Check if the user is manually entering info
  if (e.target.getAttribute("entrymethod") === "manual") {
    console.log(
      "Manual entry by user.",
      e.target.getAttribute("row"),
      e.target.name
    );
    if (e.target.value === "") {
      output[e.target.name][e.target.getAttribute("row")] = 0;
    } else {
      if (e.target.name === "interestRate") {
        if (e.target.value[e.target.value.length - 1] !== ".") {
          output[e.target.name][e.target.getAttribute("row")] = parseFloat(
            e.target.value
          );
        } else {
          output[e.target.name][e.target.getAttribute("row")] = e.target.value;
        }
      } else {
        output[e.target.name][e.target.getAttribute("row")] = parseFloat(
          e.target.value
        );
      }
    }
  }

  //Check if the user is clearing info
  if (e.target.getAttribute("entrymethod") === "clear") {
    console.log("Clear entry by user.", e.target.getAttribute("row"));
    delete output[e.target.getAttribute("name")][e.target.getAttribute("row")];
  }

  console.log(output);
  setManualData(output);
};

/**
 * Renders the results panel component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.inUse - The inUse data.
 * @param {Object} props.studentData - The student data.
 * @param {Object} props.comparisonData - The comparison data.
 * @param {Function} props.setStudentData - The function to set student data.
 * @returns {JSX.Element} The rendered ResultsPanel component.
 */
const ResultsPanel = (props) => {
  const [moreDetails, setMoreDetails] = useState(true);
  const [showModal, setShowModal] = useState(false);

  let wipeResult = null;
  let MoreDetailsResults = null;
  let variant1 = "primary";
  let variant2 = "warning";

  if (props.inUse.submit) {
    // Wipe result box is conditional
    if (props.studentData.summaryData.dateCleared) {
      wipeResult = (
        <div className="resultsBox">
          <div className="resultsBoxContent">
            <Popover
              popoverTitle="Years to clear debt"
              popoverContent={resultsPopoverData["Years to clear debt"]}
            />
          </div>
          You will clear your debt{" "}
          <h1>
            {" "}
            <Badge variant={variant1}>
              {props.studentData.summaryData.dateCleared} years
            </Badge>
            {props.inUse.inUse[1] ? (
              <>
                <small> / </small>
                <Badge variant={variant2}>
                  {props.comparisonData.summaryData.dateCleared} years
                </Badge>
              </>
            ) : null}
          </h1>
          after finishing University.{" "}
        </div>
      );
    } else {
      wipeResult = (
        <div className="resultsBox">
          <div className="resultsBoxContent">
            <Popover
              popoverTitle="Remaining balance before being written off"
              popoverContent={
                resultsPopoverData["Remaining balance before being written off"]
              }
            />
          </div>
          The remaining balance after{" "}
          {props.studentData.loanPlan === "plan1" ||
          props.studentData.loanPlan === "plan1NI"
            ? 25
            : props.studentData.loanPlan === "plan5"
            ? 40
            : 30}{" "}
          years is{" "}
          <h2>
            <Badge variant={variant1}>
              £
              {numComma(
                Math.round(
                  props.studentData.data[props.studentData.data.length - 1]
                    .accruedMinusRepayment
                )
              )}{" "}
            </Badge>
            {props.inUse.inUse[1] ? (
              <>
                <small> / </small>
                <Badge variant={variant2}>
                  £
                  {numComma(
                    Math.round(
                      props.comparisonData.data[
                        props.studentData.data.length - 1
                      ].accruedMinusRepayment
                    )
                  )}
                </Badge>
              </>
            ) : null}
          </h2>{" "}
          in student loan balance, which would be written off, which is worth{" "}
          <h2>
            <Badge variant={variant1}>
              £
              {numComma(
                Math.round(
                  props.studentData.data[props.studentData.data.length - 1]
                    .accruedMinusRepaymentReal
                )
              )}
            </Badge>{" "}
            {props.inUse.inUse[1] ? (
              <>
                <small> / </small>
                <Badge variant={variant2}>
                  £
                  {numComma(
                    Math.round(
                      props.comparisonData.data[
                        props.comparisonData.data.length - 1
                      ].accruedMinusRepaymentReal
                    )
                  )}
                </Badge>{" "}
              </>
            ) : null}
          </h2>{" "}
          in today's money.
        </div>
      );
    }

    if (moreDetails) {
      MoreDetailsResults = (
        <>
          <SalaryGraph
            variant1={variant1}
            variant2={variant2}
            inUse={props.inUse}
            comparisonData={props.comparisonData}
            studentData={props.studentData}
            setStudentData={props.setStudentData}
            className={"fade-in"}
          />
          <SalaryRepaymentGraph
            variant1={variant1}
            variant2={variant2}
            inUse={props.inUse}
            comparisonData={props.comparisonData}
            studentData={props.studentData}
            setStudentData={props.setStudentData}
            className={"fade-in"}
          />
          <div className="resultsAdBox">
            <AdSense.Google
              client="ca-pub-4419428690072900"
              slot="4403622517"
              style={{
                display: "block",
                textAlign: "center",
                minWidth: "250px",
              }}
            />
          </div>
          <TotalRepaymentsGraph
            variant1={variant1}
            variant2={variant2}
            inUse={props.inUse}
            comparisonData={props.comparisonData}
            studentData={props.studentData}
            setStudentData={props.setStudentData}
            className={"fade-in"}
          />
        </>
      );
    }
    if (props.inUse.inUse[0]) {
      let repayment = 0;
      let monthlyRepayment = 0;
      let weeklyRepayment = 0;
      repayment =
        props.studentData.data[
          props.studentData.summaryData.startingPaymentIndex
        ].repaymentAmountReal;
      monthlyRepayment = repayment / 12;
      weeklyRepayment = repayment / 52;

      //
      let repaymentComparison = 0;
      let monthlyRepaymentComparison = 0;
      let weeklyRepaymentComparison = 0;

      if (props.inUse.inUse[1]) {
        repaymentComparison =
          props.comparisonData.data[
            props.comparisonData.summaryData.startingPaymentIndex
          ].repaymentAmountReal;
        monthlyRepaymentComparison = repaymentComparison / 12;
        weeklyRepaymentComparison = repaymentComparison / 52;
      }

      return (
        <div className="resultsPanel panel fade-in">
          <ModalClass showModal={showModal} setShowModal={setShowModal} />
          <Element name="resultsScrollToEvent"></Element>
          <div className="resultsTopFlex">
            <div style={{ marginRight: "auto" }}>
              <h5>
                <i className="bi bi-bar-chart"></i> Results
              </h5>
            </div>
            <div style={{ marginBottom: "2px" }}>
              {" "}
              <ButtonGroup>
                {props.inUse.comparing ? (
                  <Button
                    className="resultsButton"
                    variant={variant2}
                    value="Print"
                    onClick={() => {
                      let forInUse = {};
                      Object.assign(forInUse, { ...props.inUse });
                      forInUse.comparing = !props.inUse.comparing;
                      if (props.inUse.comparing) {
                        forInUse.inUse[1] = false;
                      }
                      props.setInUse({ ...forInUse });
                      setShowModal(forInUse.comparing);
                    }}>
                    Comparison Mode{" "}
                    <Badge variant="dark">
                      {props.inUse.comparing ? "ON" : "OFF"}
                    </Badge>
                  </Button>
                ) : (
                  <NavLink
                    className="inputScrollToEvent compareButton"
                    to="inputScrollToEvent"
                    spy={true}
                    smooth={true}
                    duration={500}>
                    {" "}
                    <Button
                      className="resultsButton"
                      variant={variant2}
                      value="Print"
                      onClick={() => {
                        let forInUse = { ...props.inUse };
                        forInUse.comparing = !props.inUse.comparing;
                        if (props.inUse.comparing) {
                          forInUse.inUse[1] = false;
                        }
                        props.setInUse(Object.assign({}, { ...forInUse }));
                        setShowModal(forInUse.comparing);
                      }}>
                      Comparison Mode{" "}
                      <Badge variant="dark">
                        {props.inUse.comparing ? "ON" : "OFF"}
                      </Badge>
                    </Button>
                  </NavLink>
                )}
              </ButtonGroup>
            </div>
          </div>
          <div className="resultsFlex">
            {/* Repayments Box */}
            <div className="resultsBox">
              <div className="resultsBoxContent">
                <Popover
                  popoverTitle="Total Repayments"
                  popoverContent={resultsPopoverData["Total Repayments"]}
                />
              </div>
              <div>
                You would make total repayments of{" "}
                <h2>
                  <Badge variant={variant1}>
                    £
                    {numComma(
                      Math.round(
                        props.studentData.data[
                          props.studentData.data.length - 1
                        ].repaymentTotalNominal
                      )
                    )}
                  </Badge>
                  {props.inUse.inUse[1] ? (
                    <>
                      <small> / </small>
                      <Badge variant={variant2}>
                        £
                        {numComma(
                          Math.round(
                            props.comparisonData.data[
                              props.comparisonData.data.length - 1
                            ].repaymentTotalNominal
                          )
                        )}
                      </Badge>
                    </>
                  ) : null}
                </h2>
                which is worth{" "}
                <h2>
                  <Badge variant={variant1}>
                    £
                    {numComma(
                      Math.round(
                        props.studentData.data[
                          props.studentData.data.length - 1
                        ].repaymentTotalReal
                      )
                    )}
                  </Badge>{" "}
                  {props.inUse.inUse[1] ? (
                    <>
                      <small> / </small>
                      <Badge variant={variant2}>
                        £
                        {numComma(
                          Math.round(
                            props.comparisonData.data[
                              props.comparisonData.data.length - 1
                            ].repaymentTotalReal
                          )
                        )}
                      </Badge>{" "}
                    </>
                  ) : null}
                </h2>{" "}
                in today's money.
                <br />
                <br />
                <i>
                  The average real lifetime financial benefit of taking an
                  undergraduate degree in the UK is £130,000{" "}
                  <Popover
                    popoverTitle="Institute for Fiscal Studies Report, Feb 2020"
                    popoverContent={resultsPopoverData["IFS Report"]}></Popover>
                </i>
              </div>
            </div>

            {/* Graduate Debt Box */}
            {props.studentData.setLoanSize === "checked" ? null : (
              <div className="resultsBox">
                <div className="resultsBoxContent">
                  <Popover
                    popoverTitle="Debt on Graduation"
                    popoverContent={resultsPopoverData["Debt on Graduation"]}
                  />
                </div>
                You will graduate with{" "}
                <h2>
                  <Badge variant={variant1}>
                    £
                    {numComma(
                      Math.round(
                        props.studentData.data[props.studentData.courseLength]
                          .accruedMinusRepayment
                      )
                    )}{" "}
                  </Badge>
                  {props.inUse.inUse[1] ? (
                    <>
                      <small> / </small>
                      <Badge variant={variant2}>
                        £
                        {numComma(
                          Math.round(
                            props.comparisonData.data[
                              props.comparisonData.courseLength
                            ].accruedMinusRepayment
                          )
                        )}{" "}
                      </Badge>
                    </>
                  ) : null}
                </h2>
                in student loan debt, which is worth
                <h2>
                  <Badge variant={variant1}>
                    £
                    {numComma(
                      Math.round(
                        props.studentData.data[props.studentData.courseLength]
                          .accruedMinusRepaymentReal
                      )
                    )}{" "}
                  </Badge>
                  {props.inUse.inUse[1] ? (
                    <>
                      <small> / </small>
                      <Badge variant={variant2}>
                        £
                        {numComma(
                          Math.round(
                            props.comparisonData.data[
                              props.comparisonData.courseLength
                            ].accruedMinusRepaymentReal
                          )
                        )}{" "}
                      </Badge>
                    </>
                  ) : null}
                </h2>
                in today's money.
              </div>
            )}

            {/* First Year Repayment Box */}
            {props.studentData.setLoanSize === "checked" ? (
              <div className="resultsBox">
                <div className="resultsBoxContent"></div>
                {/* TODO */}
                In the coming year you will make a student loan repayment of{" "}
                <h2>
                  <Badge variant={variant1}>
                    £{numComma(Math.round(repayment))} /year
                  </Badge>
                  {props.inUse.inUse[1] ? (
                    <>
                      <small> / </small>
                      <Badge variant={variant2}>
                        £{numComma(Math.round(repaymentComparison))} /year
                      </Badge>
                    </>
                  ) : null}
                </h2>{" "}
                {repayment === 0 && repaymentComparison === 0 ? null : (
                  <>
                    or{" "}
                    <h2>
                      <Badge variant={variant1}>
                        £{numComma(Math.round(monthlyRepayment))} /month
                      </Badge>
                      {props.inUse.inUse[1] ? (
                        <>
                          <small> / </small>
                          <Badge variant={variant2}>
                            £{numComma(Math.round(monthlyRepaymentComparison))}{" "}
                            /month
                          </Badge>
                        </>
                      ) : null}
                    </h2>
                    or{" "}
                    <h2>
                      <Badge variant={variant1}>
                        £{numComma(Math.round(weeklyRepayment))} /week
                      </Badge>
                      {props.inUse.inUse[1] ? (
                        <>
                          <small> / </small>
                          <Badge variant={variant2}>
                            £{numComma(Math.round(weeklyRepaymentComparison))}{" "}
                            /week
                          </Badge>
                        </>
                      ) : null}
                    </h2>
                  </>
                )}
              </div>
            ) : (
              <div className="resultsBox">
                <div className="resultsBoxContent"></div>
                {/* TODO */}
                In your first year after graduating you would make a student
                loan repayment of{" "}
                <h2>
                  <Badge variant={variant1}>
                    £{numComma(Math.round(repayment))} /year
                  </Badge>
                  {props.inUse.inUse[1] ? (
                    <>
                      <small> / </small>
                      <Badge variant={variant2}>
                        £{numComma(Math.round(repaymentComparison))} /year
                      </Badge>
                    </>
                  ) : null}
                </h2>{" "}
                {repayment === 0 && repaymentComparison === 0 ? null : (
                  <>
                    or{" "}
                    <h2>
                      <Badge variant={variant1}>
                        £{numComma(Math.round(monthlyRepayment))} /month
                      </Badge>
                      {props.inUse.inUse[1] ? (
                        <>
                          <small> / </small>
                          <Badge variant={variant2}>
                            £{numComma(Math.round(monthlyRepaymentComparison))}{" "}
                            /month
                          </Badge>
                        </>
                      ) : null}
                    </h2>
                    or{" "}
                    <h2>
                      <Badge variant={variant1}>
                        £{numComma(Math.round(weeklyRepayment))} /week
                      </Badge>
                      {props.inUse.inUse[1] ? (
                        <>
                          <small> / </small>
                          <Badge variant={variant2}>
                            £{numComma(Math.round(weeklyRepaymentComparison))}{" "}
                            /week
                          </Badge>
                        </>
                      ) : null}
                    </h2>
                  </>
                )}
              </div>
            )}

            {/* Wipe Debt Box */}
            {wipeResult}

            {/* Overpayment Rate of Return Box */}
            {props.inUse.inUse[1] || isNaN(props.studentData.apr) ? null : (
              <div className="resultsBox">
                <div className="resultsBoxContent">
                  <Popover
                    popoverTitle="Overpayment Rate of Return"
                    popoverContent={
                      resultsPopoverData["Overpayment Rate of Return"]
                    }
                  />
                </div>
                If you made an overpayment of £100/month, the nominal rate of
                return on your overpayments would be{" "}
                <h2>
                  <Badge variant={variant1}>
                    {Math.round(props.studentData.apr * 10000) / 100}%
                  </Badge>
                </h2>{" "}
              </div>
            )}

            <TableDisplay
              appData={props}
              handleManualInput={handleManualInput}
            />
            {Math.round(
              props.studentData.data[props.studentData.data.length - 1]
                .nominalSalary
            ) === 0 &&
            Math.round(
              props.studentData.data[props.studentData.data.length - 1]
                .repaymentTotalNominal
            ) === 0 ? null : (
              <HintDismissable
                heading={null}
                studentData={props.studentData}
                p={
                  <>
                    <i className="bi bi-info-circle"></i> Hint: Hover or click
                    on bars on the graphs to show the value in specific years.
                  </>
                }
                variant={variant1}
                className={"resultsHintBox fade-in"}
              />
            )}
            <div className="resultsAdBox">
              <AdSense.Google
                client="ca-pub-4419428690072900"
                slot="4403622517"
                style={{
                  display: "block",
                  textAlign: "center",
                  minWidth: "250px",
                }}
              />
            </div>

            {/* Graphs */}
            <RepaymentGraph
              variant1={variant1}
              variant2={variant2}
              inUse={props.inUse}
              comparisonData={props.comparisonData}
              studentData={props.studentData}
              setStudentData={props.setStudentData}
            />
            <RepaymentPieGraph
              variant1={variant1}
              variant2={variant2}
              inUse={props.inUse}
              comparisonData={props.comparisonData}
              studentData={props.studentData}
              setStudentData={props.setStudentData}
              className={"fade-in"}
            />
            {MoreDetailsResults}
          </div>
          <div className="resultsButtonFlex">
            <Button
              className="resultsButton detailsButton"
              style={{
                marginRight: "auto",
              }}
              variant={variant1}
              value="More Details"
              onClick={() => {
                setMoreDetails(!moreDetails);
              }}>
              {moreDetails ? (
                <>
                  Less Detail{" "}
                  <small>
                    <i className="bi bi-arrows-angle-contract"></i>
                  </small>
                </>
              ) : (
                <>
                  More Detail{" "}
                  <small>
                    <i className="bi bi-arrows-angle-expand"></i>
                  </small>
                </>
              )}
            </Button>

            <Button
              href="https://www.buymeacoffee.com/123tomford"
              target="_blank"
              className="resultsButton"
              variant={`outline-primary`}>
              Buy me a Coffee <i className="bi bi-cup"></i>
            </Button>

            <Button
              href="https://forms.gle/XYBH7S2dGjTG53uW8"
              target="_blank"
              className="resultsButton"
              variant={`outline-primary`}>
              Send Feedback <i className="bi bi-pencil"></i>
            </Button>

            <Button
              className="resultsButton"
              variant={`outline-primary`}
              value="Print"
              onClick={() => {
                window.print();
              }}>
              Print Page <i className="bi bi-printer"></i>
            </Button>

            <Button
              className="resultsButton buyCoffeeButton"
              variant={`outline-primary`}
              value="Print"
              onClick={() => {
                props.csvData.click(); // This will download the data file named "my_data.csv".
              }}>
              Export CSV <i className="bi bi-file-earmark-spreadsheet"></i>
            </Button>

            {/* </NavLink> */}
            <NavLink
              className="topScrollToEvent"
              to="topScrollToEvent"
              spy={true}
              smooth={true}
              duration={500}>
              <Button
                className="resultsButton"
                variant={`outline-primary`}
                name="reset"
                value="ToTop">
                To Top <i className="bi bi-arrow-up-short"></i>
              </Button>
            </NavLink>
          </div>
        </div>
      );
    }
  }
  if (props.inUse.inUse[0]) {
    return (
      <div className="resultsPanel panel">
        <Element name="resultsScrollToEvent"></Element>
        <div className="resultsButtonFlex">
          <div style={{ marginRight: "auto" }}>
            <h5>
              <i className="bi bi-bar-chart"></i> Results
            </h5>
            <br />
            Press{" "}
            <Badge variant="primary">
              Calculate <i className="bi bi-arrow-right-circle"></i>
            </Badge>{" "}
            in the input panel to receive your results.
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default ResultsPanel;
