import React from "react";

/**
 * Renders the Privacy Policy panel.
 *
 * @returns {JSX.Element} The Privacy Policy panel component.
 */
const PrivacyPolicyPanel = () => {
  return (
    <div className="panel privacyPanel">
      <div>
        <h2>Privacy Policy</h2>

        <p>Last updated: 18th January 2024</p>

        <h3>1. Introduction</h3>
        <p>
          Welcome to studentcalc.co.uk. This Privacy Policy outlines how we
          collect, use, disclose, and safeguard your information when you visit
          our website and use our services. By accessing or using our website,
          you consent to the practices described in this Privacy Policy.
        </p>

        <h3>2. Information We Collect</h3>
        <p>
          We collect and store information that you voluntarily provide to us,
          including but not limited to, your name, email address, and any other
          information you submit through our website.
        </p>

        <h3>3. Use of Information</h3>
        <p>
          We use the information we collect, including personal information, for
          the following purposes:
        </p>
        <ul>
          <li>To provide, personalize, and improve our services.</li>
          <li>
            To communicate with you, respond to your inquiries, and provide
            updates.
          </li>
          <li>
            To optimize and deliver Google AdSense advertisements based on your
            interests.
          </li>
        </ul>

        <h3>4. Google AdSense</h3>
        <p>
          We use Google AdSense to display advertisements on our website. Google
          AdSense may collect certain information about you, such as your IP
          address, cookies, and similar technologies, to provide more relevant
          ads. Please refer to Google's privacy policy for more information on
          how they handle your data:{" "}
          <a href="https://policies.google.com/privacy?hl=en-US">
            Google Privacy & Terms
          </a>
        </p>

        <h3>5. Cookies</h3>
        <p>
          We use cookies to enhance your experience on our website. Cookies are
          small data files stored on your device that help us improve our
          services and understand how you interact with our website. You can
          manage your cookie preferences through your browser settings.
        </p>

        {/* Additional sections go here */}

        <h3>6. Contact Us</h3>
        <p>
          If you have any questions or concerns about this Privacy Policy,
          please contact us at{" "}
          <a href="mailto:contact@studentcalc.co.uk">
            contact@studentcalc.co.uk
          </a>
        </p>

        <p>
          By using our website, you acknowledge that you have read and
          understood this Privacy Policy.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicyPanel;
