import React from "react";
import { Badge } from "react-bootstrap";
import Popover from "../../../elements/popover";
import resultsPopoverData from "../../../data/resultsPopoverData";
import MortgageRepaymentGraph from "../graphs/mortgageRepaymentGraph";
import numComma from "../../../elements/numComma";

const MortgageResultsPanel = (props) => {
  let variant1 = "primary";

  let monthlyRepayment = (
    <div className="panelFlexRight">
      <>
        Enter mortgage details into the pane on the left to recieve your results
        and then press the calculate button.
      </>
    </div>
  );
  // Monthly repayment
  if (props.inUse.submit) {
    monthlyRepayment = (
      <div className="panelFlexRight">
        <h5>Your results </h5>
        {/* Monthly repayment box */}
        <div className="resultsBox">
          <div className="resultsBoxContent">
            <Popover
              popoverTitle="Minimum monthly repayment"
              popoverContent={resultsPopoverData["Monthly Repayment"]}
            />
          </div>

          {props.mortgageData.mortgageType === "repayment" ? (
            // If the mortgage is a repayment mortgage, show the monthly repayment
            <>Your monthly repayment will be </>
          ) : (
            // If the mortgage is interest only, show the interest payment
            <>Your monthly interest payment will be </>
          )}
          <h3>
            {" "}
            <Badge variant={variant1}>
              £{numComma(props.mortgageData.summaryData.monthlyRepayment)}
            </Badge>
          </h3>
        </div>

        {/* Total interest repaid */}
        <div className="resultsBox">
          <div className="resultsBoxContent">
            <Popover
              popoverTitle="Minimum monthly repayment"
              popoverContent={resultsPopoverData["Monthly Repayment"]}
            />
          </div>
          The total amount payable over the term will be{" "}
          <h3>
            {" "}
            <Badge variant={variant1}>
              £{numComma(props.mortgageData.summaryData.totalRepaid)}
            </Badge>
          </h3>
          (of which{" "}
          <Badge variant={variant1}>
            £{numComma(props.mortgageData.principal)}
          </Badge>{" "}
          is principal and{" "}
          <Badge variant={variant1}>
            £{numComma(props.mortgageData.summaryData.totalInterestRepaid)}
          </Badge>{" "}
          is interest)
        </div>

        <MortgageRepaymentGraph
          variant1={variant1}
          mortgageData={props.mortgageData}
        />
      </div>
    );
  }

  return monthlyRepayment;
};

export default MortgageResultsPanel;
