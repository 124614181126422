const inputData = {
  popoverTitle: {
    courseStartYear: "Year Starting Course",
    courseLength: "",
    tuitionFee: "Tuition Fee Loan",
    maintenance: "Maintenance Loan",
    salary: "Pre-Tax Salary after Graduating",
    rpi: "Retail Price Index (RPI)",
    salaryGrowth: "Your Salary Growth (Nominal)",
    earningsGrowth: "UK Average Earnings Growth (Nominal)",
    baseRate: "Bank of England Base Rate",
    currentPaymentFloor: "Current Payment Threshold",
    currentRateCeiling: "Current Interest Rate Ceiling",
    useActual: "Use Historical Data",
    setLoanSize: "Set Loan Outstanding",
    loanByYear: "Set Tuition & Maintenance by Year ",
    inflateSalary: "Increase Starting Salary with Inflation",
    currentDebt: "Current Student Loan Debt",
    overpayment: "Monthly Overpayment Amount",
    loanPlan: "Set Your Student Loan Plan",
  },
  popoverContent: {
    courseStartYear: (
      <ul>
        <li>
          Choose the calender year in which you started (or will start) your
          course.
        </li>
        <li>
          If you don't yet know which year you will start, choose the earliest
          possible year - this should still give you an idea of costs.
        </li>
      </ul>
    ),
    tuitionFee:
      "This is the average cost of your Tuition Fee per year at University. Make sure account for any bursary payments that may reduce this value.",
    maintenance: (
      <ul>
        <li>
          This is the average cost of your Maintenance Loan per year at
          University.
        </li>
        <li>
          The maximum maintenance loan you can apply for depends on your
          household income.
        </li>
      </ul>
    ),
    salary: (
      <ul>
        <li>
          This is the salary you will earn in the year following graduation.
        </li>
        <li>
          We make assumptions about how your salary will grow over time - you
          can view and adjust this rate in the advanced assumptions tab.
        </li>
        <li>
          You can manually set salary by individual year in the data table
          within the results tab.
        </li>
      </ul>
    ),
    rpi: (
      <ul>
        <li>
          The Retail Price Index (RPI) is one of the two main measures of UK
          inflation produced by the Office for National Statistics (ONS).
        </li>
        <li>
          Under Plan 2 (2012 and later) Student Loans, the interest rate depends
          on the RPI.
        </li>
        <li>During your course, the interest rate is RPI + 3%.</li>
        <li>
          After graduating, the interest rate is set to between RPI and RPI +
          3%, depending on how much you earn.
        </li>
        <li>We assume an average RPI rate to simplify the calculations.</li>
      </ul>
    ),
    salaryGrowth:
      "In calculating your repayments and interest rate, we need to make assumptions about your salary growth in the future.",
    earningsGrowth:
      "We make an assumption about average UK earnings growth because it affects the earnings threshold at which you make student loan payments.",
    baseRate:
      "We make an assumption about the Bank of England Base Rate because it affects the Plan 1 interest rate.",
    currentPaymentFloor: (
      <ul>
        <li>
          If you earn more than this threshold pre-tax each year, then you start
          making student loan repayments.
        </li>
        <li>
          If you earn less than this threshold pre-tax each year then you make
          no student finance repayments that year.
        </li>
        <li>
          Your student loan repayment each year is 9% of your pre-tax earnings
          above the threshold.
        </li>
        <li>
          We assume this increases in line with average UK earnings growth.
        </li>
      </ul>
    ),
    currentRateCeiling: (
      <ul>
        <li>
          If you earn above the interest rate ceiling, then the interest on your
          student loan is set at RPI + 3%.
        </li>
        <li>
          If you earn below the interest rate ceiling, then the interest on your
          student loan is tapered, down to RPI at the current payment threshold.
        </li>
        <li>
          We assume this increases in line with average UK earnings growth.
        </li>
      </ul>
    ),
    useActual: (
      <ul>
        <li>
          If you tick use historical data, we will use actual RPI and payment
          thresholds from the past.
        </li>
        <li>
          For example, in 2016 the payment threshold was £21,000 and RPI was
          1.6%. We can use these actual figures rather than using our assumed
          RPI.
        </li>
        <li>
          Even if you tick use historical data, we will still use the
          assumptions about RPI and payment thresholds for years in the future.
        </li>
      </ul>
    ),
    setLoanSize: (
      <ul>
        <li>
          If you tick set loan size, you will be able to enter your outstanding
          loan total.
        </li>
        <li>
          This setting is useful if you already know how much student loan debt
          you have and if you have already made payments in the past.
        </li>
        <li>If you are still at University, do not use this setting.</li>
      </ul>
    ),
    loanByYear: (
      <ul>
        <li>
          If you tick set tuition & maintenance by year, you will be able to
          enter individual tuition fee loan and maintenance loan payments by
          year within your course.
        </li>
        <li>
          This setting is useful if the tuition fee loan or maintenance loan
          total differed in particular years in your course.
        </li>
        <li>
          This setting cannot be used with the manually set loan size setting.
        </li>
      </ul>
    ),
    inflateSalary: (
      <ul>
        <li>
          If you tick increase starting salary with inflation, we will adjust
          your starting salary to reflect inflation.
        </li>
        <li>
          For example, if you start working in 2024, the nominal starting salary
          of your job will be higher than what you might expect because you
          likely haven't taken inflation into account.
        </li>
        <li>
          This setting cannot be used in conjunction with manually setting
          current loan balance.
        </li>
        <li>
          This setting will not affect calculations where the graduation date is
          in the past as we assume you set the actual nominal salary.
        </li>
      </ul>
    ),
    currentDebt: (
      <ul>
        <li>
          Enter your current total student loan debt. You can find this figure
          on the UK Government's student loan repayment website.
        </li>
        <li>
          Do not use this setting if you are still at University - instead,
          click the button above named 'I don't know my student loan balance.'
          This setting will work out what your student loan balance total will
          look like once you have completed University.
        </li>
      </ul>
    ),
    overpayment: (
      <ul>
        <li>
          If you choose, you can see what happens if you made extra monthly
          repayments on top of your standard repayments.
        </li>
        <li>
          We assume that the overpayments begin after graduating University.
        </li>
        <li>
          We assume that you increase the overpayment amount annually in line
          with inflation.
        </li>
      </ul>
    ),
    loanPlan: (
      <ul>
        <li>Pick your student loan plan from the list of options.</li>
        <li>
          If you have studied at undergraduate level in England or Wales after
          2012 to 2022, your loan will be Plan 2.
        </li>
        <li>
          If you have studied at undergraduate level in England or Wales from
          and after 2023, your loan will be Plan 5.
        </li>
        <li>
          If you have studied at undergraduate level in England or Wales
          pre-2012, your loan will be Plan 1.
        </li>
        <li>
          If you have studied at undergraduate level in Scotland, your loan will
          be Plan 4.
        </li>
        <li>
          If you have studied at postgraduate level after 2016, your loan will
          be the postgraduate loan option.
        </li>
      </ul>
    ),
  },
  min: {
    courseStartYear: 1998,
    courseLength: 1,
    tuitionFee: 0,
    maintenance: 0,
    salary: 0,
    rpi: 0,
    salaryGrowth: 0,
    earningsGrowth: 0,
    baseRate: 0,
    currentPaymentFloor: 0,
    currentRateCeiling: 0,
    useActual: null,
    setLoanSize: null,
    loanByYear: null,
    inflateSalary: null,
    currentDebt: 0,
    overpayment: 0,
  },
  max: {
    courseStartYear: 2099,
    courseLength: 10,
    tuitionFee: 1000000,
    maintenance: 1000000,
    salary: 1000000,
    rpi: 100,
    salaryGrowth: 100,
    earningsGrowth: 100,
    baseRate: 100,
    currentPaymentFloor: 1000000,
    currentRateCeiling: 1000000,
    useActual: null,
    setLoanSize: null,
    loanByYear: null,
    inflateSalary: null,
    currentDebt: 1000000,
    overpayment: 1000000,
  },
  default: {
    //Input variables
    courseStartYear: 2012,
    courseLength: 3,
    tuitionFee: 9250,
    maintenance: 9200,
    salary: 25000,
    rpi: 3,
    salaryGrowth: 5,
    earningsGrowth: 4,
    baseRate: 1,
    currentPaymentFloor: 27295,
    currentRateCeiling: 49130,
    useActual: "checked",
    setLoanSize: "checked",
    loanByYear: "unchecked",
    inflateSalary: "checked",
    currentDebt: 25000,
    overpayment: 0,
    loanPlan: "plan2",
    // Non-input Variables
    currentYear: new Date().getFullYear(),
    data: [],
    summaryData: {},
  },
  inUseDefault: {
    inUse: [true, false],
    variants: ["primary", "warning"],
    cache: [],
    comparing: false,
    recalculate: [false, false],
    submit: false,
  },
};

export default inputData;
