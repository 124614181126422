import React, { useState } from "react";
import ExplainerPanel from "./panels/explainer.js";
import InputPanel from "./panels/input.js";
import ResultsPanel from "./panels/results.js";
import HeaderPanel from "./panels/header.js";
import DisclaimerPanel from "./panels/disclaimer.js";
import SupportPanel from "./panels/support.js";
import FaqPanel from "./panels/faq.js";
import AdPanel from "./panels/ad.js";
import Scroll from "react-scroll";
import inputData from "./data/inputData";
import FooterPanel from "./panels/footer.js";
import ManualData from "./data/manualData";

const Element = Scroll.Element;

/**
 * The main component of the application.
 *
 * @returns {JSX.Element} The rendered App component.
 */
function App() {
  const [manualData, setManualData] = useState(ManualData);
  const [studentData, setStudentData] = useState(inputData.default);
  const [autoData, setAutoData] = useState(inputData.default);
  const [comparisonData, setComparisonData] = useState(inputData.default);
  const [csvData, setCsvData] = useState(null);
  const [inUse, setInUse] = useState({
    inUse: [true, false],
    variants: ["primary", "warning"],
    cache: [],
    comparing: false,
    recalculate: [false, false],
    submit: false,
  });

  return (
    <>
      <div className="content">
        <HeaderPanel className="example-screen" />
        <div className="panelFlex">
          <ExplainerPanel className="example-screen" />
          <Element name="inputScrollToEvent"></Element>
          <InputPanel
            className="example-screen"
            studentData={studentData}
            setStudentData={setStudentData}
            manualData={manualData}
            setManualData={setManualData}
            autoData={autoData}
            setAutoData={setAutoData}
            comparisonData={comparisonData}
            setComparisonData={setComparisonData}
            inUse={inUse}
            setInUse={setInUse}
            csvData={csvData}
            setCsvData={setCsvData}
          />
          <ResultsPanel
            className="example-print"
            studentData={studentData}
            setStudentData={setStudentData}
            manualData={manualData}
            setManualData={setManualData}
            autoData={autoData}
            setAutoData={setAutoData}
            comparisonData={comparisonData}
            setComparisonData={setComparisonData}
            inUse={inUse}
            setInUse={setInUse}
            csvData={csvData}
          />
          <AdPanel className="example-screen" />
          <FaqPanel className="example-screen" moreDetails={false} />
          <SupportPanel className="example-screen" />
          <AdPanel className="example-screen" />
          <DisclaimerPanel className="example-screen" />
        </div>
      </div>
      <FooterPanel className="navbar navbar-default navbar-static-bottom navbar-fixed-bottom" />
    </>
  );
}

export default App;
