import React from "react";
import AdSense from "react-adsense";

/**
 * Renders the AdPanel component.
 * @returns {JSX.Element} The rendered AdPanel component.
 */
const AdPanel = () => {
  return (
    <div className="panel aboutPanel">
      <AdSense.Google
        client="ca-pub-4419428690072900"
        slot="7207725730"
        style={{
          display: "block",
          textAlign: "center",
          minWidth: "250px",
        }}
        layout="in-article"
        format="fluid"
      />
    </div>
  );
};

export default AdPanel;
