import React from "react";
import HeaderPanel from "./panels/header.js";
import FaqPanel from "./panels/faq.js";
import FooterPanel from "./panels/footer.js";
import AdPanel from "./panels/ad.js";

/**
 * Renders the FAQ page component.
 * @returns {JSX.Element} The rendered FAQ page component.
 */

function FaqPage() {
  return (
    <>
      <div className="content">
        <HeaderPanel />
        <div className="panelFlex">
          <FaqPanel moreDetails={true} />
          <AdPanel className="example-screen" />
        </div>
      </div>
      <FooterPanel className="navbar navbar-default navbar-static-bottom navbar-fixed-bottom" />
    </>
  );
}

export default FaqPage;
