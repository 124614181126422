import { InputGroup, Form } from "react-bootstrap";
import Popover from "../../../elements/popover.js";
import inputData from "../data/mortgageInputData.js";

const InputSelect = (props) => {
  let value = props.value;
  return (
    <>
      <Form.Label>
        {value.label}
        {inputData.popoverTitle[value.name] === "" ? null : (
          <Popover
            popoverTitle={inputData.popoverTitle[value.name]}
            popoverContent={inputData.popoverContent[value.name]}
          />
        )}
      </Form.Label>
      <InputGroup className="mb-3">
        <Form.Control
          as="select"
          disabled={value.disabled}
          type="select"
          name={value.name}
          value={value.dataName}
          onChange={(e) => {
            value.handleInput(e, value.data);
          }}
          onKeyUp={(e) => {
            value.handleKeyUp(e, value.data);
          }}>
          <option value="repayment">Repayment</option>
          <option value="interestOnly">Interest only</option>
        </Form.Control>
      </InputGroup>
    </>
  );
};

export default InputSelect;
