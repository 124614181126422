import React from "react";
import inputData from "../data/inputData";
import { Card, Accordion } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";

/**
 * Renders the about input panel.
 * @returns {JSX.Element} The rendered about input panel.
 */

const aboutInputPanel = () => {
  let aboutInputs = [];
  let aboutInputsKeys = Object.keys(inputData.popoverContent);
  for (let i = 0; i < aboutInputsKeys.length; i++) {
    console.log(i + 1);
    aboutInputs.push(
      <Card key={uuidv4()}>
        <Accordion.Toggle
          className="aboutToggle"
          as={Card.Header}
          variant="link"
          eventKey={i + 1}>
          {inputData.popoverTitle[aboutInputsKeys[i]]}
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={i + 1}>
          <Card.Body>{inputData.popoverContent[aboutInputsKeys[i]]}</Card.Body>
        </Accordion.Collapse>
      </Card>
    );
  }

  return (
    <div className="panel aboutPanel">
      <div>
        <h5>About the Calculator Inputs</h5>
      </div>
      <Accordion style={{ marginTop: "20px" }} defaultActiveKey={1}>
        {aboutInputs}
      </Accordion>
    </div>
  );
};

export default aboutInputPanel;
