import { useState } from "react";
import graphFill from "../elements/graphFill";
import Popover from "../elements/popover";

import {
  VictoryChart,
  VictoryAxis,
  VictoryTooltip,
  VictoryVoronoiContainer,
  VictoryLine,
  VictoryTheme,
  VictoryScatter,
  VictoryLegend,
  Border,
} from "victory";
import numComma from "../elements/numComma";
import ToggleGroup from "../elements/toggleGroup";

const TotalRepaymentsGraph = (props) => {
  const [real, setReal] = useState("true");

  if (
    Math.round(
      props.studentData.data[props.studentData.data.length - 1]
        .repaymentTotalNominal
    ) === 0
  ) {
    return null;
  }

  return (
    <div className="resultsGraphBox">
      <div className="resultsBoxContent">
        <Popover
          popoverTitle="Real vs Nominal"
          popoverContent="Nominal refers to the stated value at a point in time. Real refers to the amount adjusted for inflation. By using real values, you can easily compare values in the future to values today."
        />
      </div>
      <div className="toggleFlex">
        <h5>
          Repayments vs Debt{" "}
          {/* <DetailsExpandable p="+" variant="primary" /> */}
        </h5>
        <ToggleGroup
          variant={props.variant1}
          handleInput={() => {
            setReal(!real);
          }}
          real={real}
        />
      </div>
      <div className="resultsGraphFlex">
        <div className="resultsGraph">
          <VictoryChart
            theme={VictoryTheme.material}
            containerComponent={
              <VictoryVoronoiContainer
                voronoiBlacklist={["scatterGraph"]}
                labels={({ datum }) =>
                  `In ${datum.year.getFullYear()} ${datum.prepend}£${numComma(
                    datum.amount
                  )}${datum.append}`
                }
                labelComponent={
                  <VictoryTooltip
                    dy={-3}
                    cornerRadius={3}
                    constrainToVisibleArea
                    style={{ fill: "rgb(250,250,250)" }}
                    flyoutPadding={6}
                    flyoutStyle={{
                      fill: "rgba(0, 0, 0, 0.8)",
                      strokeWidth: 0,
                      boxShadow: "10px 10px",
                    }}
                  />
                }
              />
            }
            domainPadding={{ y: 20 }}
            width={750}
            scale={{ x: "time", y: "linear" }}
            padding={{ top: 70, left: 100, right: 50, bottom: 50 }}>
            <VictoryLegend
              x={275}
              y={0}
              title="Key"
              centerTitle
              orientation="horizontal"
              gutter={20}
              style={{
                border: { stroke: "black" },
                title: { fontSize: 18 },
              }}
              data={[
                { name: "Total Debt", symbol: { fill: "#2b5e3a" } },
                { name: "Total Repayments", symbol: { fill: "#5e2b4f" } },
              ]}
            />
            <VictoryAxis
              label="Year"
              style={{
                axisLabel: {
                  padding: 36,
                  fontSize: 15,
                },
                grid: {
                  stroke: "darkGrey",
                },
              }}
            />
            <VictoryAxis
              dependentAxis
              label={
                real ? "Total Real Repayments £" : "Total Nominal Repayments £"
              }
              style={{
                axisLabel: {
                  padding: 60,
                  fontSize: 15,
                },
                grid: {
                  stroke: "darkGrey",
                },
              }}
            />
            <VictoryLine
              style={{
                data: {
                  stroke: "#5e2b4f",
                  strokeWidth: 2.5,
                },
                labels: {
                  fill: "black",
                  fontSize: "14px",
                },
              }}
              name="test"
              data={
                real
                  ? props.studentData.summaryData.totalRepaymentsGraph
                  : props.studentData.summaryData.totalRepaymentsGraphNominal
              }
              // data accessor for x values
              x="year"
              // data accessor for y values
              y="amount"
            />
            <VictoryScatter
              name="scatterGraph"
              style={{
                data: {
                  fill: "#5e2b4f",
                },
              }}
              size={3.5}
              data={
                real
                  ? props.studentData.summaryData.totalRepaymentsGraph
                  : props.studentData.summaryData.totalRepaymentsGraphNominal
              }
              // data accessor for x values
              x="year"
              // data accessor for y values
              y="amount"
            />
            <VictoryLine
              style={{
                data: {
                  stroke: "#2b5e3a",
                  strokeWidth: 2.5,
                },
                labels: {
                  fill: "black",
                  fontSize: "14px",
                },
              }}
              name="test"
              data={
                real
                  ? props.studentData.summaryData.totalDebt
                  : props.studentData.summaryData.totalDebtNominal
              }
              // data accessor for x values
              x="year"
              // data accessor for y values
              y="amount"
            />
            <VictoryScatter
              name="scatterGraph"
              style={{
                data: {
                  fill: "#2b5e3a",
                },
              }}
              size={3.5}
              data={
                real
                  ? props.studentData.summaryData.totalDebt
                  : props.studentData.summaryData.totalDebtNominal
              }
              // data accessor for x values
              x="year"
              // data accessor for y values
              y="amount"
            />
          </VictoryChart>

          <br />
        </div>
        {props.inUse.inUse[1] ? (
          <div className="resultsGraph">
            <VictoryChart
              theme={VictoryTheme.material}
              containerComponent={
                <VictoryVoronoiContainer
                  voronoiBlacklist={["scatterGraph"]}
                  labels={({ datum }) =>
                    `In ${datum.year.getFullYear()} ${datum.prepend}£${numComma(
                      datum.amount
                    )}${datum.append}`
                  }
                  labelComponent={
                    <VictoryTooltip
                      dy={-3}
                      cornerRadius={3}
                      constrainToVisibleArea
                      style={{ fill: "rgb(250,250,250)" }}
                      flyoutPadding={6}
                      flyoutStyle={{
                        fill: "rgba(0, 0, 0, 0.8)",
                        strokeWidth: 0,
                        boxShadow: "10px 10px",
                      }}
                    />
                  }
                />
              }
              domainPadding={{ y: 20 }}
              width={750}
              scale={{ x: "time", y: "linear" }}
              padding={{ top: 70, left: 100, right: 50, bottom: 50 }}>
              <VictoryLegend
                x={275}
                y={0}
                title="Key"
                centerTitle
                orientation="horizontal"
                gutter={20}
                style={{
                  border: { stroke: "black" },
                  title: { fontSize: 18 },
                }}
                data={[
                  { name: "Total Debt", symbol: { fill: "#2b5e3a" } },
                  {
                    name: "Total Repayments",
                    symbol: { fill: graphFill[props.variant2] },
                  },
                ]}
              />
              <VictoryAxis
                label="Year"
                style={{
                  axisLabel: {
                    padding: 36,
                    fontSize: 15,
                  },
                  grid: {
                    stroke: "darkGrey",
                  },
                }}
              />
              <VictoryAxis
                dependentAxis
                label={
                  real
                    ? "Comparison Total Real Repayments £"
                    : "Comparison Total Nominal Repayments £"
                }
                style={{
                  axisLabel: {
                    padding: 60,
                    fontSize: 15,
                  },
                  grid: {
                    stroke: "darkGrey",
                  },
                }}
              />

              <VictoryLine
                style={{
                  data: {
                    stroke: graphFill[props.variant2],
                    strokeWidth: 2.5,
                  },
                  labels: {
                    fill: "black",
                    fontSize: "14px",
                  },
                }}
                name="test"
                data={
                  real
                    ? props.comparisonData.summaryData.totalRepaymentsGraph
                    : props.comparisonData.summaryData
                        .totalRepaymentsGraphNominal
                }
                // data accessor for x values
                x="year"
                // data accessor for y values
                y="amount"
              />
              <VictoryScatter
                name="scatterGraph"
                style={{
                  data: {
                    fill: graphFill[props.variant2],
                  },
                }}
                size={3.5}
                data={
                  real
                    ? props.comparisonData.summaryData.totalRepaymentsGraph
                    : props.comparisonData.summaryData
                        .totalRepaymentsGraphNominal
                }
                // data accessor for x values
                x="year"
                // data accessor for y values
                y="amount"
              />
              <VictoryLine
                style={{
                  data: {
                    stroke: graphFill[props.variant1],
                    strokeWidth: 2.5,
                  },
                  labels: {
                    fill: "black",
                    fontSize: "14px",
                  },
                }}
                name="test"
                data={
                  real
                    ? props.comparisonData.summaryData.totalDebt
                    : props.comparisonData.summaryData.totalDebtNominal
                }
                // data accessor for x values
                x="year"
                // data accessor for y values
                y="amount"
              />
              <VictoryScatter
                name="scatterGraph"
                style={{
                  data: {
                    fill: graphFill[props.variant1],
                  },
                }}
                size={3.5}
                data={
                  real
                    ? props.comparisonData.summaryData.totalDebt
                    : props.comparisonData.summaryData.totalDebtNominal
                }
                // data accessor for x values
                x="year"
                // data accessor for y values
                y="amount"
              />
            </VictoryChart>

            <br />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default TotalRepaymentsGraph;
