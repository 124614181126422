import React from "react";
import ReactDOM from "react-dom";
import "./main.css";
import AppPage from "./AppPage";
import AboutPage from "./AboutPage";
import FaqPage from "./FaqPage";
import PrivacyPolicyPage from "./PrivacyPolicyPage";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Scroll from "react-scroll";
import MortgagePage from "./calculators/mortgageRepayments/pages/MortgageAppPage";

const Element = Scroll.Element;

ReactDOM.render(
  <>
    <BrowserRouter>
      <Element name="topScrollToEvent" />
      <Routes>
        <Route exact path="/" element={<AppPage />} />
        <Route exact path="/mortgage" element={<MortgagePage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/faqs" element={<FaqPage />} />
        <Route path="/privacypolicy" element={<PrivacyPolicyPage />} />
      </Routes>
    </BrowserRouter>
  </>,
  document.getElementById("root")
);
