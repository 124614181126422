// If you change colours here, also change them in scss/myThemes/_bootstrapOverrides.scss

const graphFill = {
  primary: "#2b5e3a",
  info: "#5e2b4f",
  warning: "#ffc107",
  // danger: "#dc3545",
  danger: "#ffa600",
};

export default graphFill;
