import React, { useState } from "react";
import faqData from "../data/faqData";
import { Card, Badge, CardColumns, Button } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";

/**
 * TextPanel component displays frequently asked questions in a card format.
 *
 * @param {Object} props - The props object containing the following properties:
 *   - moreDetails {boolean} - Indicates whether to show more details or not.
 *
 * @returns {JSX.Element} The rendered TextPanel component.
 */
const TextPanel = (props) => {
  const [moreDetails, setMoreDetails] = useState(props.moreDetails);

  let faqCards = [];
  let faqCardsKeys = Object.keys(faqData);

  let renderNumber = moreDetails ? faqCardsKeys.length : 3;

  for (let i = 0; i < renderNumber; i++) {
    faqCards.push(
      <Card key={uuidv4()}>
        <Card.Body>
          {faqData[faqCardsKeys[i]].tags.length !== "" ? (
            <Card.Title>{faqData[faqCardsKeys[i]].title}</Card.Title>
          ) : null}
          <div>{faqData[faqCardsKeys[i]].content}</div>
        </Card.Body>
        {faqData[faqCardsKeys[i]].tags.length > 0 ? (
          <Card.Footer>
            {faqData[faqCardsKeys[i]].tags.map((el) => {
              return (
                <>
                  <Badge variant="primary">{el}</Badge>{" "}
                </>
              );
            })}
          </Card.Footer>
        ) : null}{" "}
        {faqData[faqCardsKeys[i]].reference !== "" ? (
          <Card.Footer>
            Reference:{" "}
            <a
              target="_blank"
              rel="noreferrer"
              style={{ color: "DarkBlue" }}
              href={faqData[faqCardsKeys[i]].reference}>
              {faqData[faqCardsKeys[i]].reference}
            </a>
          </Card.Footer>
        ) : null}
      </Card>
    );
  }

  return (
    <div className="panel aboutPanel">
      <h5>Frequently Asked Questions</h5>
      <CardColumns style={{ marginTop: "20px" }}>{faqCards}</CardColumns>
      <div className="resultsButtonFlex">
        <Button
          className="resultsButton detailsButton"
          style={{
            marginRight: "auto",
          }}
          variant={"primary"}
          value="More Details"
          onClick={() => {
            setMoreDetails(!moreDetails);
          }}>
          {moreDetails ? (
            <>
              Less Detail{" "}
              <small>
                <i className="bi bi-arrows-angle-contract"></i>
              </small>
            </>
          ) : (
            <>
              More Detail{" "}
              <small>
                <i className="bi bi-arrows-angle-expand"></i>
              </small>
            </>
          )}
        </Button>
      </div>
    </div>
  );
};

export default TextPanel;
