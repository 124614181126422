import { Button, ButtonGroup } from "react-bootstrap";

const ToggleGroup = (props) => {
  return (
    <ButtonGroup style={{ paddingLeft: "10px" }} size="sm">
      <Button
        variant={props.real ? props.variant : `outline-${props.variant}`}
        onClick={() => {
          props.handleInput();
        }}>
        Real
      </Button>
      <Button
        variant={props.real ? `outline-${props.variant}` : props.variant}
        onClick={() => {
          props.handleInput();
        }}>
        Nominal
      </Button>
    </ButtonGroup>
  );
};

export default ToggleGroup;
