// import {
//   VictoryBar,
//   VictoryChart,
//   VictoryAxis,
//   VictoryTooltip,
//   VictoryStack,
//   VictoryTheme,
// } from "victory";
// import graphFill from "../../../elements/graphFill";
// import numComma from "../../../elements/numComma";
import Chart from "react-apexcharts";

const MortgageRepaymentGraph = (props) => {
  console.log(props.mortgageData.summaryData.cumulativeInterestPaid);
  return (
    <div className="resultsGraphBox">
      <div className="toggleFlex">
        <h5>Your mortgage debt over time</h5>
      </div>

      <div className="resultsGraphFlex">
        <div className="resultsGraph">
          <Chart
            options={{
              chart: {
                id: "Mortgage repayment chart",
                toolbar: {
                  show: false, // Set show to false to hide the toolbar buttons
                },
              },
              xaxis: {
                type: "numeric",
                forceNiceScale: true,
                decimalsInFloat: 0,
              },
              yaxis: {
                forceNiceScale: true,
                labels: {
                  formatter: function (value) {
                    return "£" + value.toLocaleString();
                  },
                },
              },
              stroke: {
                colors: ["#2b5e3a"], // Set the line color to green
              },
              markers: {
                colors: "#2b5e3a",
              },
              tooltip: {
                marker: {
                  show: false,
                },
              },
            }}
            type="line"
            series={[
              {
                name: "Mortgage balance remaining",
                data: props.mortgageData.summaryData.cumulativeInterestPaid.map(
                  (item) => item.amount
                ),
              },
            ]}
          />

          {/* <VictoryChart
            theme={VictoryTheme.material}
            domainPadding={{ x: [14, 14], y: 14 }}
            width={750}
            scale={{ x: "linear", y: "linear" }}
            padding={{ top: 30, left: 100, right: 50, bottom: 50 }}>
            <VictoryAxis
              label="Year"
              style={{
                axisLabel: {
                  padding: 36,
                  fontSize: 15,
                },
              }}
            />
            <VictoryAxis
              dependentAxis
              label={"Mortgage Debt"}
              style={{
                axisLabel: {
                  padding: 60,
                  fontSize: 15,
                },
                grid: {
                  stroke: "darkGrey",
                },
              }}
            />
            <VictoryStack>
              <VictoryBar
                alignment="start"
                style={{
                  data: {
                    fill: graphFill[props.variant1],
                    width: "14",
                  },
                  labels: {
                    fill: "black",
                    fontSize: "14px",
                  },
                }}
                name="test"
                data={props.mortgageData.summaryData.cumulativeInterestPaid}
                labels={({ datum }) =>
                  `In year ${
                    datum.year
                  } of your mortgage term you will have  £${numComma(
                    Math.round(datum.amount)
                  )} remaining mortgage balance.`
                }
                labelComponent={
                  <VictoryTooltip
                    dy={-14}
                    dx={7}
                    cornerRadius={3}
                    constrainToVisibleArea
                    style={{ fill: "rgb(250,250,250)" }}
                    flyoutPadding={6}
                    flyoutStyle={{
                      fill: "rgba(0, 0, 0, 0.8)",
                      strokeWidth: 0,
                      boxShadow: "10px 10px",
                    }}
                  />
                }
                // data accessor for x values
                x="year"
                // data accessor for y values
                y="amount"
              />
            </VictoryStack>
          </VictoryChart> */}
          <br />
        </div>
      </div>
    </div>
  );
};

export default MortgageRepaymentGraph;
