import React from "react";
import { Modal, Button, Badge } from "react-bootstrap";

const modalClass = (props) => {
  return (
    <>
      <Modal
        show={props.showModal}
        onHide={() => {
          props.setShowModal(false);
        }}
        centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <h2>
              <Badge variant="warning">Comparison Mode</Badge>
            </h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul>
            <li>Welcome to the Comparison Mode.</li>
            <li>
              You can create a new scenario to compare to the current results.
            </li>
            <li>
              Adjust the inputs and press{" "}
              <Badge variant="primary">
                Calculate <i className="bi bi-arrow-right-circle"></i>
              </Badge>{" "}
              to generate the comparison results.
            </li>
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              props.setShowModal(false);
            }}>
            Go <i className="bi bi-arrow-right-circle"></i>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default modalClass;
