import { InputGroup, FormControl, Form } from "react-bootstrap";
import Popover from "./popover.js";
import inputData from "../data/inputData";

const InputTheme = (props) => {
  let value = props.value;
  let check =
    value.dataName === "" ||
    value.dataName > inputData.max[value.name] ||
    value.dataName < inputData.min[value.name];
  let smallText = value.loanByYear ? <small>{value.label}</small> : value.label;

  return (
    <>
      <Form.Label>
        {smallText}
        {inputData.popoverTitle[value.name] === "" ||
        inputData.popoverTitle[value.name] === undefined ? null : (
          <Popover
            popoverTitle={inputData.popoverTitle[value.name]}
            popoverContent={inputData.popoverContent[value.name]}
          />
        )}
      </Form.Label>
      <InputGroup className="mb-3">
        {value.prepend === "" ? null : (
          <InputGroup.Prepend>
            <InputGroup.Text>{value.prepend}</InputGroup.Text>
          </InputGroup.Prepend>
        )}
        <FormControl
          disabled={value.disabled}
          style={{
            borderColor: check ? "rgba(193, 66, 66, 0.4)" : null,
            boxShadow: check ? "0 0 0 0.2rem rgba(193, 66, 66, 0.3)" : null,
          }}
          min={inputData.min[value.name]}
          max={inputData.max[value.name]}
          step={value.step}
          // aria-label="Username"
          // aria-describedby="basic-addon1"
          type={value.type}
          name={value.name}
          value={value.dataName}
          onChange={(e) => {
            value.handleInput(e, value.data);
          }}
          onKeyUp={(e) => {
            if (value.loanByYear === false || value.loanByYear === undefined) {
              value.handleKeyUp(e, value.data);
            }
          }}
        />
        {value.append === "" ? null : (
          <InputGroup.Append>
            <InputGroup.Text id="basic-addon1">{value.append}</InputGroup.Text>
          </InputGroup.Append>
        )}
      </InputGroup>
      {value.disabled && value.disabledText !== "" ? (
        <>
          {" "}
          <Form.Text className="text-muted">
            <i className="bi bi-exclamation-circle-fill"></i>{" "}
            {value.disabledText}
          </Form.Text>{" "}
          <br />
        </>
      ) : null}
      {check ? (
        value.loanByYear ? (
          <>
            {" "}
            <Form.Text className="text-muted">
              <i className="bi bi-exclamation-circle-fill"></i>
              {" You need to enter a value between " +
                inputData.min["tuitionFee"] +
                " and " +
                inputData.max["tuitionFee"] +
                "."}
            </Form.Text>{" "}
            <br />
          </>
        ) : (
          <>
            {" "}
            <Form.Text className="text-muted">
              <i className="bi bi-exclamation-circle-fill"></i>
              {" You need to enter a value between " +
                inputData.min[value.name] +
                " and " +
                inputData.max[value.name] +
                "."}
            </Form.Text>{" "}
            <br />
          </>
        )
      ) : null}
    </>
  );
};

export default InputTheme;
