import React, { useState } from "react";
import { Alert } from "react-bootstrap";

function HintDismissable(props) {
  const [show, setShow] = useState(true);

  if (show) {
    return (
      <Alert
        variant={props.variant}
        onClose={() => setShow(false)}
        dismissible
        className={props.className}>
        {props.heading ? <Alert.Heading>{props.heading}</Alert.Heading> : null}
        {props.p ? <>{props.p}</> : null}
      </Alert>
    );
  }
  return null;
}

export default HintDismissable;
