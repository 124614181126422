import React from "react";
import HeaderPanel from "./panels/header.js";
import AboutCalcPanel from "./panels/aboutCalc.js";
import AboutInputsPanel from "./panels/aboutInputs.js";
import SupportPanel from "./panels/support.js";
import AdPanel from "./panels/ad.js";
import FooterPanel from "./panels/footer.js";

/**
 * Renders the AboutPage component.
 * @returns {JSX.Element} The rendered AboutPage component.
 */
function AboutPage() {
  return (
    <>
      <div className="content">
        <HeaderPanel />
        <div className="panelFlex">
          <AboutCalcPanel />
          <AdPanel className="example-screen" />
          <AboutInputsPanel />
          <SupportPanel className="example-screen" />
          <AdPanel className="example-screen" />
        </div>
      </div>
      <FooterPanel className="navbar navbar-default navbar-static-bottom navbar-fixed-bottom" />
    </>
  );
}

export default AboutPage;
