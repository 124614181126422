import React, { useState } from "react";
import HeaderPanel from "../../../panels/header.js";
import FooterPanel from "../../../panels/footer.js";
import MortgageTextPanel from "../panels/mortgageTextPanel.js";
import MortgageInputPanel from "../panels/mortgageInputPanel.js";
import MortgageInputData from "../data/mortgageInputData.js";
import MortgageResultsPanel from "../panels/mortgageResultsPanel.js";

function MortgagePage() {
  const [mortgageData, setMortgageData] = useState(MortgageInputData.default);
  const [inUse, setInUse] = useState({
    inUse: [true, false],
    variants: ["primary", "warning"],
    cache: [],
    comparing: false,
    recalculate: [false, false],
    submit: false,
  });

  return (
    <>
      <div className="content">
        <HeaderPanel />
        <div className="maximumWidth">
          <MortgageTextPanel />
          <div className="panelFlexLeftToRight">
            {" "}
            <MortgageInputPanel
              mortgageData={mortgageData}
              setMortgageData={setMortgageData}
              inUse={inUse}
              setInUse={setInUse}
            />
            <MortgageResultsPanel
              mortgageData={mortgageData}
              setMortgageData={setMortgageData}
              inUse={inUse}
              setInUse={setInUse}
            />
          </div>
        </div>
      </div>
      <FooterPanel className="navbar navbar-default navbar-static-bottom navbar-fixed-bottom" />
    </>
  );
}

export default MortgagePage;
