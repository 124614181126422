import { ButtonGroup, Button } from "react-bootstrap";

const InputButtons = (props) => {
  let value = props.value;
  return (
    <>
      <ButtonGroup size="sm">
        <Button
          // Set value as unchecked because if clicked, the handleInput function will use this to determine to switch to the other case (checked)
          value="unchecked"
          name={value.name}
          variant={value.dataName === "checked" ? "primary" : "outline-primary"}
          checked={value.dataName === "checked" ? true : false}
          onClick={(e) => value.handleInput(e, value.data)}>
          {value.labels[0]}
        </Button>
        <Button
          value="checked"
          name={value.name}
          variant={
            value.dataName === "unchecked" ? "primary" : "outline-primary"
          }
          checked={value.dataName === "checked" ? false : true}
          onClick={(e) => value.handleInput(e, value.data)}>
          {value.labels[1]}
        </Button>
      </ButtonGroup>
    </>
  );
};

export default InputButtons;
