import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import versionHistory from "../versionHistory.js";
import Scroll from "react-scroll";
const ScrollLink = Scroll.Link;

/**
 * Renders the footer panel component.
 * @returns {JSX.Element} The rendered footer panel.
 */
const footerPanel = () => {
  return (
    <div className="panel footerPanel">
      <footer>
        <Navbar bg="dark" variant="dark">
          <ScrollLink
            className="topScrollToEvent compareButton"
            to="topScrollToEvent"
            spy={true}
            duration={0}>
            <NavLink to="/release">
              <Navbar.Text>
                <small>Beta Version {versionHistory.current}</small>
              </Navbar.Text>
            </NavLink>
          </ScrollLink>

          <Nav className="mr-auto"></Nav>
          <Navbar.Text href="#home">
            © Student Loan Calculator {new Date().getFullYear()}
          </Navbar.Text>
        </Navbar>
      </footer>
    </div>
  );
};

export default footerPanel;
