import React from "react";
import { Button } from "react-bootstrap";

/**
 * Renders the support panel.
 * 
 * @returns {JSX.Element} The support panel component.
 */
const supportPanel = () => {
  return (
    <div className="panel disclaimerPanel">
      <p style={{ fontSize: "1rem", margin: "0" }}>
        <h5>Support the site!</h5>
        <br />I am a one-man team who loves building tools that can help people
        better understand their finances. I offer the tools for free, but if you
        are able to support my work please buy me a coffee at the button below,
        or send some feedback to help me improve the site. Thank you!
        <br />
        <br />
        Please also consider visting my other sites:
        <br />
        <br />
        <a href="https://www.student-loan-calculator.co.uk">
          www.student-loan-calculator.co.uk
          <br />
        </a>
        <a href="https://www.makemymealmeatless.co.uk">
          www.makemymealmeatless.co.uk
        </a>
      </p>
      <div className="resultsButtonFlex">
        <Button
          href="https://www.buymeacoffee.com/123tomford"
          target="_blank"
          className="resultsButton"
          variant={`outline-primary`}>
          Buy me a Coffee <i className="bi bi-cup"></i>
        </Button>
        <Button
          href="https://forms.gle/XYBH7S2dGjTG53uW8"
          target="_blank"
          className="resultsButton"
          variant={`outline-primary`}>
          Send Feedback <i className="bi bi-pencil"></i>
        </Button>
      </div>
    </div>
  );
};

export default supportPanel;
