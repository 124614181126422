import {
  VictoryBar,
  VictoryChart,
  VictoryAxis,
  VictoryTooltip,
  VictoryTheme,
} from "victory";
import graphFill from "../elements/graphFill";
import Popover from "../elements/popover";

const SalaryRepaymentGraph = (props) => {
  if (
    Math.round(
      props.studentData.data[props.studentData.data.length - 1]
        .repaymentTotalNominal
    ) === 0
  ) {
    return null;
  }

  return (
    <div className="resultsGraphBox">
      <div className="resultsBoxContent">
        {/* <Popover popoverTitle="Example Title" popoverContent="Example Text" /> */}
      </div>
      <h5>Repayments as a Percentage of Salary</h5>
      <div className="resultsGraphFlex">
        <div className="resultsGraph">
          <VictoryChart
            theme={VictoryTheme.material}
            domainPadding={{ x: [14, 14], y: 14 }}
            width={750}
            // animate={{
            //   duration: 1800,
            //   easing: "linear",
            // }}
            scale={{ x: "time", y: "linear" }}
            padding={{ top: 30, left: 100, right: 50, bottom: 50 }}>
            <VictoryAxis
              label="Year"
              style={{
                axisLabel: {
                  padding: 36,
                  fontSize: 15,
                },
              }}
            />
            <VictoryAxis
              dependentAxis
              label="Repayment %"
              style={{
                axisLabel: {
                  padding: 60,
                  fontSize: 15,
                },

                grid: {
                  stroke: "darkGrey",
                },
              }}
            />
            <VictoryBar
              alignment="middle"
              style={{
                data: {
                  fill: graphFill[props.variant1],
                  width: "14",
                },
                labels: { fill: "black", fontSize: "14px" },
              }}
              name="test"
              data={props.studentData.summaryData.salaryRepaymentGraph}
              labels={({ datum }) =>
                datum.amount > 0
                  ? `In ${datum.year.getFullYear()} your student loan repayments will ${
                      props.studentData.currentYear > datum.year.getFullYear()
                        ? "have been"
                        : "be"
                    } ${datum.amount}% of your salary`
                  : null
              }
              labelComponent={
                <VictoryTooltip
                  dy={-14}
                  dx={0}
                  cornerRadius={3}
                  constrainToVisibleArea
                  style={{ fill: "rgb(250,250,250)" }}
                  flyoutPadding={6}
                  flyoutStyle={{
                    fill: "rgba(0, 0, 0, 0.8)",
                    strokeWidth: 0,
                    boxShadow: "10px 10px",
                  }}
                />
              }
              // data accessor for x values
              x="year"
              // data accessor for y values
              y="amount"
            />
          </VictoryChart>
          <br />
        </div>

        {props.inUse.inUse[1] ? (
          <div className="resultsGraph">
            <VictoryChart
              theme={VictoryTheme.material}
              domainPadding={{ x: [14, 14], y: 14 }}
              width={750}
              scale={{ x: "time", y: "linear" }}
              padding={{ top: 50, left: 100, right: 50, bottom: 50 }}>
              <VictoryAxis
                label="Year"
                style={{
                  axisLabel: {
                    padding: 36,
                    fontSize: 15,
                  },
                }}
              />
              <VictoryAxis
                dependentAxis
                label="Repayment %"
                style={{
                  axisLabel: {
                    padding: 60,
                    fontSize: 15,
                  },
                  grid: {
                    stroke: "darkGrey",
                  },
                }}
              />
              <VictoryBar
                alignment="middle"
                style={{
                  data: {
                    fill: graphFill[props.variant2],
                    width: "14",
                  },
                  labels: { fill: "black", fontSize: "14px" },
                }}
                name="test"
                data={props.comparisonData.summaryData.salaryRepaymentGraph}
                labels={({ datum }) =>
                  datum.amount > 0
                    ? `In ${datum.year.getFullYear()} your student loan repayments will ${
                        props.comparisonData.currentYear >
                        datum.year.getFullYear()
                          ? "have been"
                          : "be"
                      } ${datum.amount}% of your salary`
                    : null
                }
                labelComponent={
                  <VictoryTooltip
                    dy={-14}
                    dx={0}
                    cornerRadius={3}
                    constrainToVisibleArea
                    style={{ fill: "rgb(250,250,250)" }}
                    flyoutPadding={6}
                    flyoutStyle={{
                      fill: "rgba(0, 0, 0, 0.8)",
                      strokeWidth: 0,
                      boxShadow: "10px 10px",
                    }}
                  />
                }
                // data accessor for x values
                x="year"
                // data accessor for y values
                y="amount"
              />
            </VictoryChart>
            <br />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default SalaryRepaymentGraph;
