import { Form, Alert, Button } from "react-bootstrap";
import MortgageInputTheme from "../components/mortgageInputTheme.js";
import MortgageInputSelect from "../components/mortgageInputSelect.js";
import mortgageCalculator from "../calculations/mortgageCalculator.js";

const MortgageInputPanel = (props) => {
  const handleInput = (e, input) => {
    let output = {};
    Object.assign(output, input.mortgageData);

    let newInUse = {};
    Object.assign(newInUse, { ...input.inUse });
    newInUse.submit = false;
    input.setInUse({ ...newInUse });

    // If we are dealing with a checkbox, handle things differently:
    console.log(e.target.name, e.target.type, e.target.value);

    if (e.target.value === "checked") {
      output[e.target.name] = "unchecked";
    } else if (e.target.value === "unchecked") {
      output[e.target.name] = "checked";
    } else if (e.target.value === "") {
      output[e.target.name] = "";
      // If we are dealing with a selection box, handle things differently:
    } else if (e.target.type === "select-one") {
      output[e.target.name] = e.target.value;
      // Otherwise, parse the input as a float
    } else {
      output[e.target.name] = parseFloat(e.target.value);
    }

    input.setMortgageData(output);
  };

  const handleSubmit = (e, props) => {
    console.log("submitting");
    // Get the data from the inputs
    let input = props.mortgageData;
    // Create the results
    let calculated = mortgageCalculator(input);

    let output = {};
    Object.assign(output, input);

    output.summaryData = calculated.summaryData;

    let forInUse = {};
    Object.assign(forInUse, { ...props.inUse });
    forInUse.recalculate[0] = true;
    forInUse.submit = true;

    props.setInUse({ ...forInUse });
    props.setMortgageData(output);
  };

  const handleKeyUp = (event, props) => {
    // handleSubmit(event, props);
  };

  return (
    <div className="panelFlexLeft">
      <Form>
        <h5>Inputs </h5>
        <hr />
        <MortgageInputTheme
          value={{
            label: "Mortgage Principal ",
            prepend: "£",
            dataName: props.mortgageData.principal,
            step: 10000,
            type: "number",
            name: "principal",
            data: props,
            append: "",
            handleInput: handleInput,
            handleKeyUp: handleKeyUp,
          }}
        />
        <MortgageInputTheme
          value={{
            label: "Mortgage Term (Years) ",
            prepend: "",
            dataName: props.mortgageData.term,
            step: 5,
            type: "number",
            name: "term",
            data: props,
            append: "",
            handleInput: handleInput,
            handleKeyUp: handleKeyUp,
          }}
        />
        <MortgageInputTheme
          value={{
            label: "Mortgage Interest Rate ",
            prepend: "",
            dataName: props.mortgageData.rate,
            step: 0.1,
            type: "number",
            name: "rate",
            data: props,
            append: "%",
            handleInput: handleInput,
            handleKeyUp: handleKeyUp,
          }}
        />{" "}
        <MortgageInputSelect
          value={{
            label: "Mortgage type ",
            prepend: "",
            dataName: props.mortgageData.mortgageType,
            step: null,
            type: "select",
            name: "mortgageType",
            data: props,
            append: "",
            handleInput: handleInput,
            handleKeyUp: handleKeyUp,
          }}
        />{" "}
        <hr />
      </Form>
      <Alert variant="primary">
        <i className="bi bi-info-circle"></i> This calculator does not
        constitute financial advice.
      </Alert>
      <Button
        className="inputButton"
        variant={props.inUse.comparing ? "warning" : "primary"}
        type="button"
        name="submit"
        value="Calculate"
        onClick={(e) => {
          handleSubmit(e, props);
        }}>
        Calculate <i className="bi bi-arrow-right-circle"></i>
      </Button>
    </div>
  );
};

export default MortgageInputPanel;
