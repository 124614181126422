import React from "react";
import HeaderPanel from "./panels/header.js";
import PrivacyPolicyPanel from "./panels/privacyPolicy.js";
import FooterPanel from "./panels/footer.js";

function PrivacyPolicyPage() {
  return (
    <>
      <div className="content">
        <HeaderPanel />
        <div className="panelFlex">
          <PrivacyPolicyPanel />
        </div>
      </div>
      <FooterPanel className="navbar navbar-default navbar-static-bottom navbar-fixed-bottom" />
    </>
  );
}

export default PrivacyPolicyPage;
