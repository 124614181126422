import React, { useEffect, useRef } from "react";

// This custom hook has the same effect as the useEffect hook, except that it doesn't run on the intial render, only when the deps change.
const useDidMountEffect = (func, deps) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) func();
    else didMount.current = true;
  }, deps);
};

export default useDidMountEffect;
