const versionHistory = {
  current: "0.7.4",
  history: {
    "0.2.1": "Add set loan value feature (05/04/21)",
    "0.2.2": "Add overpayments feature (05/04/21)",
    "0.2.3": "Add caching data structures (05/04/21)",
    "0.2.4": "Add a total debt graph (06/04/21)",
    "0.2.5": "Add stack graph for overpayments (07/04/21)",
    "0.2.6": "Add repayment pie graph (10/04/21)",
    "0.2.7": "Fix boxes at small screen sizes (11/04/21)",
    "0.2.8": "Adding compare tool (11/04/21)",
    "0.2.9": "Fix the comparison mode (16/04/21)",
    "0.2.10": "Add release page (17/04/21)",
    "0.3.0": "Set loan by year (20/04/21)",
    "0.3.1": "Add Popover for results (21/04/21)",
    "0.3.2": "Accordion About Page (21/04/21)",
    "0.3.3": "Added Neumorphism (23/04/21)",
    "0.3.4": "Add FAQs (25/04/21)",
    "0.3.5": "Remaining Balance after 30 Years (27/04/21).",
    "0.3.6": "Started Adding Comparison Graphs (27/04/21)",
    "0.3.7": "Added Comparison Graphs (28/04/21)",
    "0.3.8": "Added Adverts (01/05/21)",
    "0.3.9": "Add blog link and medium logo (01/05/21)",
    "0.3.1": "Added more details button to faq (01/05/21)",
    "0.4.0": "Added Student Loan Plans (03/05/21)",
    "0.4.1": "Postgraduate Debt Repayment at 6% (03/05/21)",
    "0.5.0": "Added other Plans (04/05/21)",
    "0.5.1": "Fix set tuition and maintenance by year (04/05/21)",
    "0.6.0": "Add Student Loan Balance vs I don't know buttons (26/08/21)",
    "0.7.0": "Feature: Adjust salary by individual year (15/04/22)",
    "0.7.1": "Adjust Overpayments by year (16/04/22)",
    "0.7.2": "Added remaining popover text (18/04/22)",
    "0.7.3": "Added feedback form link (23/04/22)",
    "0.7.4": "Adjust interest rate by year (23/04/22)",
  },
};

export default versionHistory;
